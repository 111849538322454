var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { class: _vm.itemClasses },
    [
      _c(
        "div",
        { class: _vm.headerClasses, on: { click: _vm.toggle } },
        [
          !_vm.hideArrow
            ? _c("b-icon", { attrs: { name: "ios-arrow-forward" } })
            : _vm._e(),
          _vm._v(" "),
          _vm._t("title", [_vm._v("\n      " + _vm._s(_vm.title) + "\n    ")])
        ],
        2
      ),
      _vm._v(" "),
      _vm.mounted
        ? _c("collapse-transition", [
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.isActive,
                    expression: "isActive"
                  }
                ],
                class: _vm.contentClasses
              },
              [_c("div", { class: _vm.boxClasses }, [_vm._t("default")], 2)]
            )
          ])
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }