var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      ref: "scroller",
      class: _vm.prefixCls,
      style: _vm.wrapStyle,
      on: { scroll: _vm.handleScroll }
    },
    [
      !_vm.isEmpty
        ? [
            _c("div", {
              class: _vm.prefixCls + "-phantom",
              style: { height: _vm.contentHeight }
            }),
            _vm._v(" "),
            _c(
              "div",
              {
                class: _vm.prefixCls + "-content",
                style: { transform: "translateY(" + _vm.offset + "px)" }
              },
              _vm._l(_vm.visibleData, function(node, index) {
                return _c(
                  "div",
                  {
                    key: node.id || index,
                    class: _vm.prefixCls + "-node",
                    style: {
                      paddingLeft: 18 * (node.level - 1) + "px",
                      height: _vm.itemHeight + "px"
                    }
                  },
                  [
                    _c(
                      "div",
                      {
                        class: _vm.arrowClasses(node),
                        on: {
                          click: function($event) {
                            return _vm.handleExpand(node)
                          }
                        }
                      },
                      [
                        node[_vm.childrenKey] && node[_vm.childrenKey].length
                          ? _c("b-icon", {
                              attrs: { name: "md-arrow-dropright" }
                            })
                          : _vm._e()
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _vm.showCheckbox
                      ? _c("b-checkbox", {
                          attrs: {
                            value: node.checked,
                            indeterminate: node.indeterminate,
                            disabled: node.disabled || node.disableCheckbox
                          },
                          nativeOn: {
                            click: function($event) {
                              $event.preventDefault()
                              return _vm.handleCheck(node)
                            }
                          }
                        })
                      : _vm._e(),
                    _vm._v(" "),
                    node.display
                      ? _c("span", {
                          class: _vm.titleClasses(node),
                          domProps: { innerHTML: _vm._s(node.display) },
                          on: {
                            click: function($event) {
                              return _vm.handleSelect(node)
                            }
                          }
                        })
                      : _c(
                          "span",
                          {
                            class: _vm.titleClasses(node),
                            on: {
                              click: function($event) {
                                return _vm.handleSelect(node)
                              }
                            }
                          },
                          [_vm._v(_vm._s(node.title))]
                        )
                  ],
                  1
                )
              }),
              0
            )
          ]
        : _c(
            "div",
            [
              _c("b-empty", { staticStyle: { margin: "16px 0" } }, [
                _vm._v(_vm._s(_vm.emptyText))
              ])
            ],
            1
          )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }