var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "bin-tabs-wrapper",
      class: [_vm.type, { closable: _vm.closable }]
    },
    [
      _c("scroll-pane", { ref: "scrollPane", staticClass: "nav-wrapper" }, [
        _c(
          "div",
          { staticClass: "tab-list" },
          [
            _vm.type === "default"
              ? _c("div", {
                  staticClass: "tabs-active-bar",
                  style: _vm.activeBarStyle
                })
              : _vm._e(),
            _vm._v(" "),
            _vm._l(_vm.data, function(tab, index) {
              return _c(
                "span",
                {
                  key: tab.key || "tab-" + index,
                  ref: "tabs",
                  refInFor: true,
                  staticClass: "tab-item",
                  class: [
                    { "width-icon": tab.icon },
                    { "no-close": tab.noClose },
                    { active: _vm.isActive(tab) }
                  ],
                  attrs: { id: tab.key },
                  on: {
                    click: function($event) {
                      $event.stopPropagation()
                      return _vm.handleSelectTab(tab)
                    },
                    contextmenu: function($event) {
                      $event.stopPropagation()
                      $event.preventDefault()
                      return _vm.openMenu(tab, $event)
                    }
                  }
                },
                [
                  tab.icon
                    ? _c("b-icon", {
                        staticClass: "custom-icon",
                        attrs: { name: tab.icon }
                      })
                    : _vm._e(),
                  _vm._v("\n      " + _vm._s(tab.title) + "\n      "),
                  _vm.type !== "default" && _vm.closable && !tab.noClose
                    ? _c("i", {
                        staticClass: "iconfont icon-ios-close",
                        on: {
                          click: function($event) {
                            $event.preventDefault()
                            $event.stopPropagation()
                            return _vm.closeSelectedTab(tab)
                          }
                        }
                      })
                    : _vm._e()
                ],
                1
              )
            })
          ],
          2
        )
      ]),
      _vm._v(" "),
      _vm.contextMenu
        ? [
            _c("transition", { attrs: { name: "zoom-in-top" } }, [
              _c(
                "ul",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.visible,
                      expression: "visible"
                    }
                  ],
                  staticClass: "contextmenu",
                  style: { left: _vm.left + "px", top: _vm.top + "px" }
                },
                [_vm._t("menu", [_c("li", [_vm._v("自定义插入li标签")])])],
                2
              )
            ])
          ]
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }