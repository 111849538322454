var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { class: _vm.wrapClasses }, [
    _c("div", { class: [_vm.prefixCls + "-tail"] }, [_c("i")]),
    _vm._v(" "),
    _c("div", { class: [_vm.prefixCls + "-head"] }, [
      _c("div", { class: [_vm.prefixCls + "-head-inner"] }, [
        !_vm.icon &&
        !_vm.$slots.icon &&
        _vm.currentStatus !== "finish" &&
        _vm.currentStatus !== "error"
          ? _c("span", [
              _vm._v("\n        " + _vm._s(_vm.stepNumber) + "\n      ")
            ])
          : _vm.$slots.icon
          ? _c("span", { staticClass: "bin-steps-icon" }, [_vm._t("icon")], 2)
          : _c("span", { class: _vm.iconClasses })
      ])
    ]),
    _vm._v(" "),
    _c("div", { class: [_vm.prefixCls + "-main"] }, [
      _c(
        "div",
        { class: [_vm.prefixCls + "-title"] },
        [_vm._t("title", [_vm._v(_vm._s(_vm.title))])],
        2
      ),
      _vm._v(" "),
      _vm.content || _vm.$slots.content
        ? _c(
            "div",
            { class: [_vm.prefixCls + "-content"] },
            [_vm._t("content", [_vm._v(_vm._s(_vm.content))])],
            2
          )
        : _vm._e()
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }