var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      class: [
        "bin-progress",
        _vm.status ? "progress-" + _vm.status : "",
        {
          "bin-progress-text": !_vm.showText,
          "bin-progress-text-inside": _vm.textInside
        }
      ],
      attrs: {
        role: "progressbar",
        "aria-valuenow": _vm.percent,
        "aria-valuemin": "0",
        "aria-valuemax": "100"
      }
    },
    [
      _c(
        "div",
        { staticClass: "bin-progress-bar", class: { "is-active": _vm.active } },
        [
          _c(
            "div",
            { staticClass: "bin-progress-bar__outer", style: _vm.barOutStyle },
            [
              _c(
                "div",
                { staticClass: "bin-progress-bar__inner", style: _vm.barStyle },
                [
                  _vm.showText && _vm.textInside
                    ? _c(
                        "div",
                        { staticClass: "bin-progress-bar__innerText" },
                        [_vm._v(_vm._s(_vm.percent) + "%")]
                      )
                    : _vm._e()
                ]
              )
            ]
          )
        ]
      ),
      _vm._v(" "),
      _vm.showText && !_vm.textInside
        ? _c(
            "div",
            {
              staticClass: "bin-progress__text",
              style: { fontSize: _vm.progressTextSize + "px" }
            },
            [
              !_vm.status
                ? [_vm._v(_vm._s(_vm.percent) + "%")]
                : [
                    _vm.status === "text"
                      ? _vm._t("default")
                      : _c("b-icon", { attrs: { name: _vm.iconClass } })
                  ]
            ],
            2
          )
        : _vm._e()
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }