var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      ref: "reference",
      attrs: { tabindex: "0" },
      on: {
        click: _vm.handleClick,
        keydown: [
          function($event) {
            if (
              !$event.type.indexOf("key") &&
              _vm._k($event.keyCode, "esc", 27, $event.key, ["Esc", "Escape"])
            ) {
              return null
            }
            return _vm.handleEscape($event)
          },
          function($event) {
            if (
              !$event.type.indexOf("key") &&
              _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
            ) {
              return null
            }
            return _vm.handleEnter($event)
          },
          function($event) {
            if (
              !$event.type.indexOf("key") &&
              _vm._k($event.keyCode, "left", 37, $event.key, [
                "Left",
                "ArrowLeft"
              ])
            ) {
              return null
            }
            if ("button" in $event && $event.button !== 0) {
              return null
            }
            return _vm.handleArrow($event, "x", _vm.left)
          },
          function($event) {
            if (
              !$event.type.indexOf("key") &&
              _vm._k($event.keyCode, "right", 39, $event.key, [
                "Right",
                "ArrowRight"
              ])
            ) {
              return null
            }
            if ("button" in $event && $event.button !== 2) {
              return null
            }
            return _vm.handleArrow($event, "x", _vm.right)
          },
          function($event) {
            if (
              !$event.type.indexOf("key") &&
              _vm._k($event.keyCode, "up", 38, $event.key, ["Up", "ArrowUp"])
            ) {
              return null
            }
            return _vm.handleArrow($event, "y", _vm.up)
          },
          function($event) {
            if (
              !$event.type.indexOf("key") &&
              _vm._k($event.keyCode, "down", 40, $event.key, [
                "Down",
                "ArrowDown"
              ])
            ) {
              return null
            }
            return _vm.handleArrow($event, "y", _vm.down)
          }
        ],
        blur: _vm.blurColor,
        focus: _vm.focusColor
      }
    },
    [
      _vm._l(_vm.list, function(item, index) {
        return [
          _c(
            "div",
            {
              key: item + ":" + index,
              class: [_vm.prefixCls + "-picker-colors-wrapper"]
            },
            [
              _c("div", { attrs: { "data-color-id": index } }, [
                _c("div", {
                  class: [_vm.prefixCls + "-picker-colors-wrapper-color"],
                  style: { background: item }
                }),
                _vm._v(" "),
                _c("div", {
                  ref: "color-circle-" + index,
                  refInFor: true,
                  class: [
                    _vm.prefixCls + "-picker-colors-wrapper-circle",
                    _vm.hideClass
                  ]
                })
              ])
            ]
          ),
          _vm._v(" "),
          _vm.lineBreak(_vm.list, index) ? _c("br") : _vm._e()
        ]
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }