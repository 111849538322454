var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "bin-badge" },
    [
      _vm._t("default"),
      _vm._v(" "),
      _c("transition", { attrs: { name: "zoom-in-center" } }, [
        _c("sup", {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value:
                !_vm.hidden && (_vm.content || _vm.content === 0 || _vm.isDot),
              expression: "!hidden && (content || content === 0 || isDot)"
            }
          ],
          staticClass: "bin-badge__content",
          class: [
            "bin-badge-is-" + _vm.type,
            {
              "is-fixed": _vm.$slots.default,
              "is-dot": _vm.isDot
            }
          ],
          domProps: { textContent: _vm._s(_vm.content) }
        })
      ])
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }