var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "bin-desc" }, [
    _vm.title || _vm.extra || _vm.$slots.title || _vm.$slots.extra
      ? _c("div", { staticClass: "bin-desc__header" }, [
          _c(
            "div",
            { staticClass: "bin-desc__title" },
            [_vm._t("title", [_vm._v(_vm._s(_vm.title))])],
            2
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "bin-desc__extra" },
            [_vm._t("extra", [_vm._v(_vm._s(_vm.extra))])],
            2
          )
        ])
      : _vm._e(),
    _vm._v(" "),
    _c("div", { staticClass: "bin-desc__body" }, [
      _c(
        "table",
        {
          class: [
            { "is-bordered": _vm.border },
            _vm.size ? "bin-desc--" + _vm.size : ""
          ]
        },
        [
          _c(
            "tbody",
            [
              _vm._l(_vm.rows, function(row, index) {
                return [_c("b-desc-row", { key: index, attrs: { row: row } })]
              })
            ],
            2
          )
        ]
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }