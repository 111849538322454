<template>
  <ul class="bin-dropdown-menu">
    <slot></slot>
  </ul>
</template>
<script>
export default {
  name: 'BDropdownMenu'
}
</script>
