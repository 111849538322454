var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { class: _vm.wrapClasses, style: _vm.circleSize }, [
    _c("svg", { attrs: { viewBox: "0 0 100 100" } }, [
      _c("path", {
        style: _vm.trailStyle,
        attrs: {
          d: _vm.pathString,
          stroke: _vm.trailColor,
          "stroke-width": _vm.trailWidth,
          "fill-opacity": 0
        }
      }),
      _vm._v(" "),
      _c("path", {
        style: _vm.pathStyle,
        attrs: {
          d: _vm.pathString,
          "stroke-linecap": _vm.strokeLinecap,
          stroke: _vm.strokeColor,
          "stroke-width": _vm.computedStrokeWidth,
          "fill-opacity": "0"
        }
      })
    ]),
    _vm._v(" "),
    _c("div", { class: _vm.innerClasses }, [_vm._t("default")], 2)
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }