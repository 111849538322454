var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "li",
    {
      class: _vm.classes,
      on: { mouseenter: _vm.handleMouseenter, mouseleave: _vm.handleMouseleave }
    },
    [
      _c(
        "div",
        {
          ref: "reference",
          class: [_vm.prefixCls + "-submenu-title"],
          style: _vm.titleStyle,
          on: {
            click: function($event) {
              $event.stopPropagation()
              return _vm.handleClick($event)
            }
          }
        },
        [
          _vm._t("title"),
          _vm._v(" "),
          _c("b-icon", {
            class: [_vm.prefixCls + "-submenu-title-icon"],
            attrs: { name: _vm.arrowName }
          })
        ],
        2
      ),
      _vm._v(" "),
      _vm.mode === "vertical"
        ? _c("collapse-transition", [
            _c(
              "ul",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.opened,
                    expression: "opened"
                  }
                ],
                class: [_vm.prefixCls]
              },
              [_vm._t("default")],
              2
            )
          ])
        : _c(
            "transition",
            { attrs: { name: "zoom-in-top" } },
            [
              _c(
                "drop",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.opened,
                      expression: "opened"
                    },
                    { name: "transfer-dom", rawName: "v-transfer-dom" }
                  ],
                  ref: "drop",
                  style: _vm.dropStyle,
                  attrs: {
                    placement: _vm.placement,
                    "class-name":
                      "bin-menu-horizontal-dropdown " + _vm.rootMenu.theme,
                    "data-transfer": _vm.transfer,
                    transfer: _vm.transfer
                  },
                  nativeOn: {
                    mouseenter: function($event) {
                      return _vm.handleMouseenter($event)
                    },
                    mouseleave: function($event) {
                      return _vm.handleMouseleave($event)
                    }
                  }
                },
                [
                  _c(
                    "ul",
                    { class: [_vm.prefixCls + "-drop-list"] },
                    [_vm._t("default")],
                    2
                  )
                ]
              )
            ],
            1
          )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }