var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "click-outside",
          rawName: "v-click-outside",
          value: _vm.onClickOutside,
          expression: "onClickOutside"
        }
      ],
      class: _vm.classes
    },
    [
      _c(
        "div",
        {
          ref: "reference",
          class: _vm.selectionCls,
          attrs: { tabindex: _vm.selectTabindex },
          on: {
            blur: _vm.toggleHeaderFocus,
            focus: _vm.toggleHeaderFocus,
            click: _vm.toggleMenu,
            mouseenter: function($event) {
              _vm.hasMouseHoverHead = true
            },
            mouseleave: function($event) {
              _vm.hasMouseHoverHead = false
            }
          }
        },
        [
          _vm._t("input", [
            _c("input", {
              attrs: { type: "hidden", name: _vm.name },
              domProps: { value: _vm.publicValue }
            }),
            _vm._v(" "),
            _c(
              "select-head",
              {
                attrs: {
                  filterable: _vm.filterable,
                  multiple: _vm.multiple,
                  values: _vm.values,
                  clearable: _vm.canBeCleared,
                  prefix: _vm.prefix,
                  disabled: _vm.disabled,
                  remote: _vm.remote,
                  "input-element-id": _vm.elementId,
                  "initial-label": _vm.initialLabel,
                  placeholder: _vm.placeholder,
                  "query-prop": _vm.query,
                  "max-tag-count": _vm.maxTagCount,
                  "max-tag-placeholder": _vm.maxTagPlaceholder
                },
                on: {
                  "query-change": _vm.onQueryChange,
                  "input-focus": function($event) {
                    _vm.isFocused = true
                  },
                  "input-blur": function($event) {
                    _vm.isFocused = false
                  },
                  clear: _vm.clearSingleSelect
                }
              },
              [_vm._t("prefix", null, { slot: "prefix" })],
              2
            )
          ])
        ],
        2
      ),
      _vm._v(" "),
      _c(
        "transition",
        { attrs: { name: "zoom-in-top" } },
        [
          _c(
            "drop",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.dropVisible,
                  expression: "dropVisible"
                },
                { name: "transfer-dom", rawName: "v-transfer-dom" }
              ],
              ref: "dropdown",
              class: _vm.dropdownCls,
              attrs: {
                placement: _vm.placement,
                "data-transfer": _vm.appendToBody,
                transfer: _vm.appendToBody
              }
            },
            [
              _c(
                "ul",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.showNotFoundLabel,
                      expression: "showNotFoundLabel"
                    }
                  ],
                  class: [_vm.prefixCls + "-not-found"]
                },
                [_c("li", [_vm._v(_vm._s(_vm.notFoundText))])]
              ),
              _vm._v(" "),
              _c(
                "ul",
                { class: _vm.prefixCls + "-dropdown-list" },
                [
                  !_vm.remote || (_vm.remote && !_vm.loading)
                    ? _c("functional-options", {
                        attrs: {
                          options: _vm.selectOptions,
                          "slot-update-hook": _vm.updateSlotOptions,
                          "slot-options": _vm.slotOptions
                        }
                      })
                    : _vm._e()
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "ul",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.loading,
                      expression: "loading"
                    }
                  ],
                  class: [_vm.prefixCls + "-loading"]
                },
                [_vm._v("loading...")]
              )
            ]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }