var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { class: ["bin-skeleton__item", "bin-skeleton__" + _vm.variant] },
    [_vm.variant === "image" ? _c("img-placeholder") : _vm._e()],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }