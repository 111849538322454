var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { class: _vm.classes }, [
    _vm.label || _vm.$slots.label
      ? _c(
          "label",
          {
            class: [_vm.prefixCls + "-label"],
            style: _vm.labelStyles,
            attrs: { for: _vm.labelFor }
          },
          [_vm._t("label", [_vm._v(_vm._s(_vm.label))])],
          2
        )
      : _c("label", {
          class: [_vm.prefixCls + "-label-empty"],
          style: _vm.labelStyles
        }),
    _vm._v(" "),
    _c(
      "div",
      { class: [_vm.prefixCls + "-content"], style: _vm.contentStyles },
      [
        _vm._t("default"),
        _vm._v(" "),
        _c("transition", { attrs: { name: "zoom-in-top" } }, [
          _vm.validateState === "error" &&
          _vm.showMessage &&
          _vm.form.showMessage
            ? _c("div", { class: [_vm.prefixCls + "-error-tip"] }, [
                _vm._v(_vm._s(_vm.validateMessage) + "\n      ")
              ])
            : _vm._e()
        ])
      ],
      2
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }