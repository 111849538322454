var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "tr",
    [
      _vm._l(_vm.row, function(cell, index) {
        return [
          _vm.BDesc.border
            ? [
                _c("b-desc-cell", {
                  key: "tr3-" + index + "-label",
                  attrs: { cell: cell, tag: "td", type: "label" }
                }),
                _vm._v(" "),
                _c("b-desc-cell", {
                  key: "tr3-" + index + "-content",
                  attrs: { cell: cell, tag: "td", type: "content" }
                })
              ]
            : _c("b-desc-cell", {
                key: "tr3-" + index + "-both",
                attrs: { cell: cell, tag: "td", type: "both" }
              })
        ]
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }