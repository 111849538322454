var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "click-outside",
          rawName: "v-click-outside",
          value: _vm.onClickOutside,
          expression: "onClickOutside"
        }
      ],
      class: [_vm.prefixCls],
      on: { mouseenter: _vm.handleMouseenter, mouseleave: _vm.handleMouseleave }
    },
    [
      _c(
        "div",
        {
          ref: "reference",
          class: _vm.relClasses,
          on: {
            click: _vm.handleClick,
            contextmenu: function($event) {
              $event.preventDefault()
              return _vm.handleRightClick($event)
            }
          }
        },
        [_vm._t("default")],
        2
      ),
      _vm._v(" "),
      _c(
        "transition",
        { attrs: { name: "zoom-in-top" } },
        [
          _c(
            "drop",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.currentVisible,
                  expression: "currentVisible"
                },
                { name: "transfer-dom", rawName: "v-transfer-dom" }
              ],
              ref: "drop",
              class: _vm.dropdownCls,
              attrs: {
                placement: _vm.placement,
                "data-transfer": _vm.appendToBody,
                transfer: _vm.appendToBody
              },
              nativeOn: {
                mouseenter: function($event) {
                  return _vm.handleMouseenter($event)
                },
                mouseleave: function($event) {
                  return _vm.handleMouseleave($event)
                }
              }
            },
            [_vm._t("list")],
            2
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }