<template>
  <div :class="classes" v-click-outside="handleClose">
    <div :class="[prefixCls + '-rel']" @click="toggleOpen" ref="reference">
      <input type="hidden" :name="name" :value="currentValue">
      <slot>
        <b-input
            :element-id="elementId"
            ref="input"
            :readonly="!filterable"
            :disabled="disabled"
            :value="displayInputRender"
            @change="handleInput"
            :size="size"
            :placeholder="inputPlaceholder"></b-input>
        <div :class="[prefixCls + '-label']" v-show="filterable && query === ''" @click="handleFocus">
          {{ displayRender }}
        </div>
        <b-icon name="ios-close-circle" :class="[prefixCls + '-arrow']" v-show="showCloseIcon"
                @click.native.stop="clearSelect"></b-icon>
        <b-icon name="ios-arrow-down" :class="[prefixCls + '-arrow']"></b-icon>
      </slot>
    </div>
    <transition name="zoom-in-top">
      <drop
          v-show="visible"
          :class="{ [prefixCls + '-transfer']: transfer }"
          ref="drop"
          :data-transfer="transfer"
          :transfer="transfer"
          v-transfer-dom>
        <div>
          <cas-panel
              v-show="!filterable || (filterable && query === '')"
              ref="caspanel"
              :prefix-cls="prefixCls"
              :data="data"
              :disabled="disabled"
              :change-on-select="changeOnSelect"
              :trigger="trigger"></cas-panel>
          <div :class="[prefixCls + '-dropdown']" v-show="filterable && query !== '' && querySelections.length">
            <ul :class="[selectPrefixCls + '-dropdown-list']">
              <li :class="[selectPrefixCls + '-item', { [selectPrefixCls + '-item-disabled']: item.disabled }]"
                  v-for="(item, index) in querySelections" :key="index"
                  @click="handleSelectItem(index)" v-html="item.display"></li>
            </ul>
          </div>
          <ul v-show="(filterable && query !== '' && !querySelections.length) || !data.length"
              :class="[prefixCls + '-not-found-tip']">
            <li>{{ notFoundText }}</li>
          </ul>
        </div>
      </drop>
    </transition>
  </div>
</template>

<script>
import Drop from '../select/drop.vue'
import CasPanel from './cas-panel.vue'
import TransferDom from '../../directive/transfer-dom'
import ClickOutside from '../../directive/clickoutside'
import { oneOf } from '../../utils/util'
import Emitter from '../../mixins/emitter'

const prefixCls = 'bin-cascader'
const selectPrefixCls = 'bin-select'

export default {
  name: 'BCascader',
  mixins: [Emitter],
  components: { Drop, CasPanel },
  directives: { ClickOutside, TransferDom },
  props: {
    data: {
      type: Array,
      default() {
        return []
      }
    },
    value: {
      type: Array,
      default() {
        return []
      }
    },
    disabled: {
      type: Boolean,
      default: false
    },
    clearable: {
      type: Boolean,
      default: true
    },
    placeholder: {
      type: String,
      default: '请选择'
    },
    size: {
      validator(value) {
        return oneOf(value, ['small', 'large', 'default', 'mini'])
      },
      default: 'default'
    },
    trigger: {
      validator(value) {
        return oneOf(value, ['click', 'hover'])
      },
      default: 'click'
    },
    changeOnSelect: {
      type: Boolean,
      default: false
    },
    renderFormat: {
      type: Function,
      default(label) {
        return label.join(' / ')
      }
    },
    loadData: {
      type: Function
    },
    filterable: {
      type: Boolean,
      default: false
    },
    notFoundText: {
      type: String,
      default: '暂无匹配数据'
    },
    transfer: {
      type: Boolean,
      default: false
    },
    name: {
      type: String
    },
    elementId: {
      type: String
    }
  },
  data() {
    return {
      prefixCls: prefixCls,
      selectPrefixCls: selectPrefixCls,
      visible: false,
      selected: [],
      tmpSelected: [],
      updatingValue: false, // to fix set value in changeOnSelect type
      currentValue: this.value,
      query: '',
      validDataStr: '',
      isLoadedChildren: false
    }
  },
  computed: {
    classes() {
      return [
        `${prefixCls}`,
        {
          [`${prefixCls}-show-clear`]: this.showCloseIcon,
          [`${prefixCls}-size-${this.size}`]: !!this.size,
          [`${prefixCls}-visible`]: this.visible,
          [`${prefixCls}-disabled`]: this.disabled,
          [`${prefixCls}-not-found`]: this.filterable && this.query !== '' && !this.querySelections.length
        }
      ]
    },
    showCloseIcon() {
      return this.currentValue && this.currentValue.length && this.clearable && !this.disabled
    },
    displayRender() {
      let label = []
      for (let i = 0; i < this.selected.length; i++) {
        label.push(this.selected[i].label)
      }

      return this.renderFormat(label, this.selected)
    },
    displayInputRender() {
      return this.filterable ? '' : this.displayRender
    },
    inputPlaceholder() {
      return this.filterable && this.currentValue.length ? null : this.placeholder
    },
    querySelections() {
      let selections = []

      function getSelections(arr, label, value) {
        for (let i = 0; i < arr.length; i++) {
          let item = arr[i]
          item.__label = label ? label + ' / ' + item.label : item.label
          item.__value = value ? value + ',' + item.value : item.value

          if (item.children && item.children.length) {
            getSelections(item.children, item.__label, item.__value)
            delete item.__label
            delete item.__value
          } else {
            selections.push({
              label: item.__label,
              value: item.__value,
              display: item.__label,
              item: item,
              disabled: !!item.disabled
            })
          }
        }
      }

      getSelections(this.data)
      selections = selections.filter(item => {
        return item.label ? item.label.indexOf(this.query) > -1 : false
      }).map(item => {
        item.display = item.display.replace(new RegExp(this.query, 'g'), `<span>${this.query}</span>`)
        return item
      })
      return selections
    }
  },
  methods: {
    clearSelect() {
      if (this.disabled) return false
      const oldVal = JSON.stringify(this.currentValue)
      this.currentValue = this.selected = this.tmpSelected = []
      this.handleClose()
      this.emitValue(this.currentValue, oldVal)
      this.broadcast('CasPanel', 'clear')
    },
    handleClose() {
      this.visible = false
    },
    toggleOpen() {
      if (this.disabled) return false
      if (this.visible) {
        if (!this.filterable) this.handleClose()
      } else {
        this.onFocus()
      }
    },
    onFocus() {
      this.visible = true
      if (!this.currentValue.length) {
        this.broadcast('CasPanel', 'clear')
      }
    },
    updateResult(result) {
      this.tmpSelected = result
    },
    updateSelected(init = false, changeOnSelectDataChange = false) {
      // #2793 当数据更改并设置值时，changeOnSelectDataChange用于changeOnSelect
      if (!this.changeOnSelect || init || changeOnSelectDataChange) {
        this.broadcast('CasPanel', 'find-selected', {
          value: this.currentValue
        })
      }
    },
    emitValue(val, oldVal) {
      if (JSON.stringify(val) !== oldVal) {
        this.$emit('change', this.currentValue, JSON.parse(JSON.stringify(this.selected)))
        this.$nextTick(() => {
          this.dispatch('BFormItem', 'form-change', {
            value: this.currentValue,
            selected: JSON.parse(JSON.stringify(this.selected))
          })
        })
      }
    },
    handleInput(event) {
      this.query = event.target.value
    },
    handleSelectItem(index) {
      const item = this.querySelections[index]

      if (item.item.disabled) return false
      this.query = ''
      this.$refs.input.currentValue = ''
      const oldVal = JSON.stringify(this.currentValue)
      this.currentValue = item.value.split(',')
      // use setTimeout for #4786, can not use nextTick, because @find-selected use nextTick
      setTimeout(() => {
        this.emitValue(this.currentValue, oldVal)
        this.handleClose()
      }, 0)
    },
    handleFocus() {
      this.$refs.input.focus()
    },
    // 排除 loading 后的 data，避免重复触发 updateSelect
    getValidData(data) {
      function deleteData(item) {
        const newItem = Object.assign({}, item)
        if ('loading' in newItem) {
          delete newItem.loading
        }
        if ('__value' in newItem) {
          delete newItem.__value
        }
        if ('__label' in newItem) {
          delete newItem.__label
        }
        if ('children' in newItem && newItem.children.length) {
          newItem.children = newItem.children.map(i => deleteData(i))
        }
        return newItem
      }

      return data.map(item => deleteData(item))
    }
  },
  created() {
    this.validDataStr = JSON.stringify(this.getValidData(this.data))
    this.$on('result-change', (params) => {
      // lastValue: is click the final val
      // fromInit: is this emit from update value
      const lastValue = params.lastValue
      const changeOnSelect = params.changeOnSelect
      const fromInit = params.fromInit

      if (lastValue || changeOnSelect) {
        const oldVal = JSON.stringify(this.currentValue)
        this.selected = this.tmpSelected

        let newVal = []
        this.selected.forEach((item) => {
          newVal.push(item.value)
        })

        if (!fromInit) {
          this.updatingValue = true
          this.currentValue = newVal
          this.emitValue(this.currentValue, oldVal)
        }
      }
      if (lastValue && !fromInit) {
        this.handleClose()
      }
    })
  },
  mounted() {
    this.updateSelected(true)
  },
  watch: {
    visible(val) {
      if (val) {
        if (this.currentValue.length) {
          this.updateSelected()
        }
        if (this.transfer) {
          this.$refs.drop.update()
        }
        this.broadcast('Drop', 'update-popper')
      } else {
        if (this.filterable) {
          this.query = ''
          this.$refs.input.currentValue = ''
        }
        if (this.transfer) {
          this.$refs.drop.destroy()
        }
        this.broadcast('Drop', 'destroy-popper')
      }
      this.$emit('visible-change', val)
    },
    value(val) {
      this.currentValue = val
      if (!val.length) this.selected = []
    },
    currentValue() {
      this.$emit('input', this.currentValue)
      if (this.updatingValue) {
        this.updatingValue = false
        return
      }
      this.updateSelected(true)
    },
    data: {
      deep: true,
      handler() {
        const validDataStr = JSON.stringify(this.getValidData(this.data))
        if (validDataStr !== this.validDataStr) {
          this.validDataStr = validDataStr
          if (!this.isLoadedChildren) {
            this.$nextTick(() => this.updateSelected(false, this.changeOnSelect))
          }
          this.isLoadedChildren = false
        }
      }
    }
  }
}
</script>
