import { render, staticRenderFns } from "./alpha.vue?vue&type=template&id=56df7552&"
import script from "./alpha.vue?vue&type=script&lang=js&"
export * from "./alpha.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/Users/wangbin/workspace/project/bin-ui/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('56df7552')) {
      api.createRecord('56df7552', component.options)
    } else {
      api.reload('56df7552', component.options)
    }
    module.hot.accept("./alpha.vue?vue&type=template&id=56df7552&", function () {
      api.rerender('56df7552', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/color-picker/alpha.vue"
export default component.exports