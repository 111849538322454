var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { class: _vm.classes, style: _vm.wrapStyles },
    _vm._l(_vm.notices, function(notice) {
      return _c("notice", {
        key: notice.name,
        attrs: {
          "prefix-cls": _vm.prefixCls,
          type: notice.type,
          content: notice.content,
          duration: notice.duration,
          render: notice.render,
          "has-title": notice.hasTitle,
          withIcon: notice.withIcon,
          closable: notice.closable,
          name: notice.name,
          "transition-name": notice.transitionName,
          background: notice.background,
          "msg-type": notice.msgType,
          "on-close": notice.onClose
        }
      })
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }