var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [{ name: "transfer-dom", rawName: "v-transfer-dom" }],
      attrs: { "data-transfer": _vm.appendToBody }
    },
    [
      _c("transition", { attrs: { name: "fade-in" } }, [
        _vm.mask
          ? _c("div", {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.visible,
                  expression: "visible"
                }
              ],
              class: _vm.maskClasses,
              style: _vm.wrapStyles,
              on: { click: _vm.handleMask }
            })
          : _vm._e()
      ]),
      _vm._v(" "),
      _c(
        "div",
        {
          class: _vm.wrapClasses,
          style: _vm.wrapStyles,
          on: { click: _vm.handleWrapClick }
        },
        [
          _c("transition", { attrs: { name: "move-" + _vm.placement } }, [
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.visible,
                    expression: "visible"
                  }
                ],
                class: _vm.classes,
                style: _vm.mainStyles
              },
              [
                _c("div", { ref: "content", class: _vm.contentClasses }, [
                  _vm.closable
                    ? _c(
                        "a",
                        {
                          staticClass: "bin-drawer-close",
                          on: { click: _vm.close }
                        },
                        [
                          _vm._t("close", [
                            _c("b-icon", { attrs: { name: "close" } })
                          ])
                        ],
                        2
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.showHead
                    ? _c(
                        "div",
                        { class: [_vm.prefixCls + "-header"] },
                        [
                          _vm._t("header", [
                            _c(
                              "div",
                              { class: [_vm.prefixCls + "-header-inner"] },
                              [_vm._v(_vm._s(_vm.title))]
                            )
                          ])
                        ],
                        2
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "div",
                    { class: [_vm.prefixCls + "-body"], style: _vm.styles },
                    [_vm._t("default")],
                    2
                  ),
                  _vm._v(" "),
                  _vm.$slots.footer
                    ? _c("div", { class: [_vm.prefixCls + "-footer"] }, [
                        _c(
                          "div",
                          { staticStyle: { "text-align": "right" } },
                          [_vm._t("footer")],
                          2
                        )
                      ])
                    : _vm._e()
                ]),
                _vm._v(" "),
                _vm.draggable
                  ? _c(
                      "div",
                      {
                        staticClass: "bin-drawer-drag",
                        class: {
                          "bin-drawer-drag-left": _vm.placement === "left"
                        },
                        on: { mousedown: _vm.handleTriggerMousedown }
                      },
                      [
                        _vm._t("trigger", [
                          _c(
                            "div",
                            { staticClass: "bin-drawer-drag-move-trigger" },
                            [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "bin-drawer-drag-move-trigger-point"
                                },
                                [_c("i"), _c("i"), _c("i"), _c("i"), _c("i")]
                              )
                            ]
                          )
                        ])
                      ],
                      2
                    )
                  : _vm._e()
              ]
            )
          ])
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }