var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.showSizer || _vm.showElevator
    ? _c("div", { class: _vm.optsClasses }, [
        _vm.showSizer
          ? _c(
              "div",
              { class: _vm.sizerClasses },
              [
                _c(
                  "b-select",
                  {
                    attrs: {
                      size: _vm.size,
                      placement: _vm.placement,
                      transfer: _vm.transfer
                    },
                    on: { change: _vm.changeSize },
                    model: {
                      value: _vm.currentPageSize,
                      callback: function($$v) {
                        _vm.currentPageSize = $$v
                      },
                      expression: "currentPageSize"
                    }
                  },
                  _vm._l(_vm.pageSizeOpts, function(item) {
                    return _c(
                      "b-option",
                      {
                        key: item,
                        staticStyle: { "text-align": "center" },
                        attrs: { value: item }
                      },
                      [_vm._v("\n        " + _vm._s(item) + " 条/页\n      ")]
                    )
                  }),
                  1
                )
              ],
              1
            )
          : _vm._e(),
        _vm._v(" "),
        _vm.showElevator
          ? _c("div", { class: _vm.ElevatorClasses }, [
              _vm._v("\n    跳至\n    "),
              _c("label", [
                _c("input", {
                  attrs: {
                    type: "text",
                    autocomplete: "off",
                    spellcheck: "false"
                  },
                  domProps: { value: _vm.currentTo },
                  on: {
                    keyup: function($event) {
                      if (
                        !$event.type.indexOf("key") &&
                        _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                      ) {
                        return null
                      }
                      return _vm.changePage($event)
                    }
                  }
                })
              ]),
              _vm._v("\n    页\n  ")
            ])
          : _vm._e()
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }