var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "bin-anchor-wrapper" }, [
    _c(
      "div",
      { staticClass: "bin-anchor" },
      [
        _c(
          "div",
          { staticClass: "bin-anchor-ink" },
          [
            _vm.icon
              ? _c("b-icon", {
                  style: _vm.iconStyle,
                  attrs: { name: _vm.icon, color: _vm.activeColorStr }
                })
              : _vm._e(),
            _vm._v(" "),
            _c("span", {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: !_vm.icon && _vm.showInk,
                  expression: "!icon&&showInk"
                }
              ],
              staticClass: "bin-anchor-ink-ball",
              style: { borderColor: _vm.activeColorStr, top: _vm.inkTop + "px" }
            }),
            _vm._v(" "),
            _c("span", {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: !_vm.icon && !_vm.showInk,
                  expression: "!icon&&!showInk"
                }
              ],
              staticClass: "bin-anchor-ink-line",
              style: {
                backgroundColor: _vm.activeColorStr,
                top: _vm.inkTop + "px"
              }
            })
          ],
          1
        ),
        _vm._v(" "),
        _vm._t("default")
      ],
      2
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }