var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      class: [_vm.prefixCls + "-body-wrapper"],
      on: {
        mousedown: function($event) {
          $event.preventDefault()
        }
      }
    },
    [
      _c(
        "div",
        { class: [_vm.prefixCls + "-body"] },
        [
          _vm.showDate
            ? _c("div", { class: [_vm.timePrefixCls + "-header"] }, [
                _vm._v(_vm._s(_vm.visibleDate))
              ])
            : _vm._e(),
          _vm._v(" "),
          _c(
            "div",
            { class: [_vm.prefixCls + "-content"] },
            [
              _c("time-spinner", {
                ref: "timeSpinner",
                attrs: {
                  "show-seconds": _vm.showSeconds,
                  steps: _vm.steps,
                  hours: _vm.timeSlots[0],
                  minutes: _vm.timeSlots[1],
                  seconds: _vm.timeSlots[2],
                  "disabled-hours": _vm.disabledHMS.disabledHours,
                  "disabled-minutes": _vm.disabledHMS.disabledMinutes,
                  "disabled-seconds": _vm.disabledHMS.disabledSeconds,
                  "hide-disabled-options": _vm.hideDisabledOptions
                },
                on: {
                  change: _vm.handleChange,
                  "pick-click": _vm.handlePickClick
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _vm.confirm
            ? _c("confirm", {
                on: {
                  "pick-clear": _vm.handlePickClear,
                  "pick-success": _vm.handlePickSuccess
                }
              })
            : _vm._e()
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }