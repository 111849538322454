var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.group && _vm.btnModel
    ? _c(
        "label",
        {
          directives: [
            { name: "click-animation", rawName: "v-click-animation" }
          ],
          class: _vm.wrapClasses
        },
        [
          _c("span", { class: _vm.radioClasses }, [
            _c("span", { class: _vm.innerClasses }),
            _vm._v(" "),
            _c("input", {
              class: _vm.inputClasses,
              attrs: {
                type: "radio",
                disabled: (_vm.parent && _vm.parent.disabled) || _vm.disabled,
                name: _vm.groupName
              },
              domProps: { checked: _vm.currentValue },
              on: { change: _vm.change, focus: _vm.onFocus, blur: _vm.onBlur }
            })
          ]),
          _vm._v(" "),
          _c("span", [_vm._t("default", [_vm._v(_vm._s(_vm.label))])], 2)
        ]
      )
    : _c("label", { class: _vm.wrapClasses }, [
        _c("span", { class: _vm.radioClasses }, [
          _c("span", { class: _vm.innerClasses }),
          _vm._v(" "),
          _c("input", {
            class: _vm.inputClasses,
            attrs: {
              type: "radio",
              disabled: (_vm.parent && _vm.parent.disabled) || _vm.disabled,
              name: _vm.groupName
            },
            domProps: { checked: _vm.currentValue },
            on: { change: _vm.change, focus: _vm.onFocus, blur: _vm.onBlur }
          })
        ]),
        _vm._v(" "),
        _c("span", [_vm._t("default", [_vm._v(_vm._s(_vm.label))])], 2)
      ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }