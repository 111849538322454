var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.type !== "text" && _vm.animationType === "click"
    ? _c(
        "button",
        {
          directives: [
            { name: "click-animation", rawName: "v-click-animation" }
          ],
          staticClass: "bin-button",
          class: [
            "bin-button--" + _vm.type,
            "bin-button--" + _vm.size,
            {
              "is-disabled": _vm.disabled,
              "is-loading": _vm.loading,
              "is-plain": _vm.plain,
              "is-round": _vm.round,
              "is-dashed": _vm.dashed,
              "is-transparent": _vm.transparent,
              "is-background": _vm.background
            }
          ],
          attrs: {
            disabled: _vm.disabled || _vm.loading,
            type: _vm.nativeType
          },
          on: { click: _vm.handleClick }
        },
        [
          _vm.loading
            ? _c("b-icon", {
                staticClass: "button-loading icon-is-rotating",
                style: _vm.iconStyles,
                attrs: { name: _vm.loadingIcon ? _vm.loadingIcon : "loading" }
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.icon && !_vm.loading
            ? _c("i", {
                class: ["iconfont", "icon-" + _vm.icon],
                style: _vm.iconStyles
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.$slots.default
            ? _c("span", { style: _vm.textStyle }, [_vm._t("default")], 2)
            : _vm._e()
        ],
        1
      )
    : _vm.type !== "text" && _vm.animationType === "waves"
    ? _c(
        "button",
        {
          directives: [
            {
              name: "waves",
              rawName: "v-waves",
              value: _vm.waveColor,
              expression: "waveColor"
            }
          ],
          staticClass: "bin-button",
          class: [
            "bin-button--" + _vm.type,
            "bin-button--" + _vm.size,
            {
              "is-disabled": _vm.disabled,
              "is-loading": _vm.loading,
              "is-plain": _vm.plain,
              "is-round": _vm.round,
              "is-dashed": _vm.dashed,
              "is-transparent": _vm.transparent,
              "is-background": _vm.background
            }
          ],
          attrs: {
            disabled: _vm.disabled || _vm.loading,
            type: _vm.nativeType
          },
          on: { click: _vm.handleClick }
        },
        [
          _vm.loading
            ? _c("b-icon", {
                staticClass: "button-loading icon-is-rotating",
                style: _vm.iconStyles,
                attrs: { name: _vm.loadingIcon ? _vm.loadingIcon : "loading" }
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.icon && !_vm.loading
            ? _c("i", {
                class: ["iconfont", "icon-" + _vm.icon],
                style: _vm.iconStyles
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.$slots.default
            ? _c("span", { style: _vm.textStyle }, [_vm._t("default")], 2)
            : _vm._e()
        ],
        1
      )
    : _c(
        "button",
        {
          staticClass: "bin-button",
          class: [
            "bin-button--" + _vm.type,
            { "is-disabled": _vm.disabled, "is-loading": _vm.loading }
          ],
          attrs: {
            disabled: _vm.disabled || _vm.loading,
            type: _vm.nativeType
          },
          on: { click: _vm.handleClick }
        },
        [
          _vm.icon && !_vm.loading
            ? _c("i", {
                class: ["iconfont", "icon-" + _vm.icon],
                style: _vm.iconStyles
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.$slots.default
            ? _c("span", { style: _vm.textStyle }, [_vm._t("default")], 2)
            : _vm._e()
        ]
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }