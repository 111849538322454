var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { class: _vm.headCls, on: { click: _vm.onHeaderClick } },
    [
      _vm.$slots.prefix || _vm.prefix
        ? _c(
            "span",
            { class: [_vm.prefixCls + "-prefix"] },
            [
              _vm._t("prefix", [
                _vm.prefix
                  ? _c("b-icon", { attrs: { name: _vm.prefix } })
                  : _vm._e()
              ])
            ],
            2
          )
        : _vm._e(),
      _vm._v(" "),
      _vm._l(_vm.selectedMultiple, function(item, index) {
        return [
          _vm.maxTagCount === undefined || index < _vm.maxTagCount
            ? _c(
                "div",
                { key: index, staticClass: "bin-tag bin-tag-checked" },
                [
                  _c("span", { staticClass: "bin-tag-text" }, [
                    _vm._v(_vm._s(item.label))
                  ]),
                  _vm._v(" "),
                  _c("b-icon", {
                    attrs: { name: "ios-close" },
                    nativeOn: {
                      click: function($event) {
                        $event.stopPropagation()
                        return _vm.removeTag(item)
                      }
                    }
                  })
                ],
                1
              )
            : _vm._e()
        ]
      }),
      _vm._v(" "),
      _vm.maxTagCount !== undefined &&
      _vm.selectedMultiple.length > _vm.maxTagCount
        ? _c("div", { staticClass: "bin-tag bin-tag-checked" }, [
            _c(
              "span",
              { staticClass: "bin-tag-text bin-select-max-tag" },
              [
                _vm.maxTagPlaceholder
                  ? [
                      _vm._v(
                        _vm._s(
                          _vm.maxTagPlaceholder(
                            _vm.selectedMultiple.length - _vm.maxTagCount
                          )
                        )
                      )
                    ]
                  : [
                      _vm._v(
                        "+ " +
                          _vm._s(
                            _vm.selectedMultiple.length - _vm.maxTagCount
                          ) +
                          "..."
                      )
                    ]
              ],
              2
            )
          ])
        : _vm._e(),
      _vm._v(" "),
      _c(
        "span",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.singleDisplayValue,
              expression: "singleDisplayValue"
            }
          ],
          class: _vm.singleDisplayClasses
        },
        [_vm._v(_vm._s(_vm.singleDisplayValue))]
      ),
      _vm._v(" "),
      _c("label", [
        _vm.filterable
          ? _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.query,
                  expression: "query"
                }
              ],
              ref: "input",
              class: [_vm.prefixCls + "-input"],
              style: _vm.inputStyle,
              attrs: {
                id: _vm.inputElementId,
                type: "text",
                disabled: _vm.disabled,
                placeholder: _vm.showPlaceholder ? _vm.placeholder : "",
                autocomplete: "off",
                spellcheck: "false"
              },
              domProps: { value: _vm.query },
              on: {
                keydown: [
                  _vm.resetInputState,
                  function($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "delete", [8, 46], $event.key, [
                        "Backspace",
                        "Delete",
                        "Del"
                      ])
                    ) {
                      return null
                    }
                    return _vm.handleInputDelete($event)
                  }
                ],
                focus: _vm.onInputFocus,
                blur: _vm.onInputBlur,
                input: function($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.query = $event.target.value
                }
              }
            })
          : _vm._e()
      ]),
      _vm._v(" "),
      _vm.resetSelect
        ? _c("b-icon", {
            class: [_vm.prefixCls + "-arrow"],
            attrs: { name: "ios-close-circle" },
            nativeOn: {
              click: function($event) {
                $event.stopPropagation()
                return _vm.onClear($event)
              }
            }
          })
        : _vm._e(),
      _vm._v(" "),
      !_vm.resetSelect && !_vm.remote
        ? _c("b-icon", {
            class: [_vm.prefixCls + "-arrow"],
            attrs: { name: "ios-arrow-down" }
          })
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }