var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { class: _vm.itemClasses },
    [
      _c(
        "div",
        {
          staticClass: "header",
          class: { "is-collapse": _vm.collapse && !_vm.$slots.right },
          on: { click: _vm.toggle }
        },
        [
          _vm._t("title", [
            _c("div", { staticClass: "label" }, [
              _vm._v("\n        " + _vm._s(_vm.title) + "\n      ")
            ])
          ]),
          _vm._v(" "),
          _vm.$slots.right
            ? _c(
                "div",
                {
                  staticClass: "right",
                  class: { "can-collapse": _vm.collapse }
                },
                [
                  _vm._t("right", [
                    _vm._v("\n        " + _vm._s(_vm.title) + "\n      ")
                  ])
                ],
                2
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "arrow",
              style: { cursor: _vm.$slots.right ? "pointer" : null },
              on: { click: _vm.arrowToggle }
            },
            [
              _vm.collapse
                ? _c("b-icon", { attrs: { name: "ios-arrow-down" } })
                : _vm._e()
            ],
            1
          )
        ],
        2
      ),
      _vm._v(" "),
      _vm.mounted
        ? _c("collapse-transition", [
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.isActive,
                    expression: "isActive"
                  }
                ],
                staticClass: "content"
              },
              [
                _c(
                  "div",
                  { staticClass: "content-box" },
                  [_vm._t("default")],
                  2
                )
              ]
            )
          ])
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }