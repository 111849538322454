var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("li", { class: [_vm.prefixCls + "-item-group"] }, [
    _c(
      "div",
      { class: [_vm.prefixCls + "-item-group-title"], style: _vm.groupStyle },
      [_vm._v(_vm._s(_vm.title))]
    ),
    _vm._v(" "),
    _c("ul", [_vm._t("default")], 2)
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }