var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { class: _vm.wrapClasses, style: _vm.styles },
    [
      _c("div", { class: _vm.classes }, [
        _vm.showSlotHeader
          ? _c(
              "div",
              { ref: "title", class: [_vm.prefixCls + "-title"] },
              [_vm._t("header")],
              2
            )
          : _vm._e(),
        _vm._v(" "),
        _vm.showHeader
          ? _c(
              "div",
              {
                ref: "header",
                class: [_vm.prefixCls + "-header"],
                on: { mousewheel: _vm.handleMouseWheel }
              },
              [
                _c("table-head", {
                  attrs: {
                    "prefix-cls": _vm.prefixCls,
                    styleObject: _vm.tableHeaderStyle,
                    columns: _vm.cloneColumns,
                    "column-rows": _vm.columnRows,
                    "obj-data": _vm.objData,
                    "columns-width": _vm.columnsWidth,
                    data: _vm.rebuildData
                  }
                })
              ],
              1
            )
          : _vm._e(),
        _vm._v(" "),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: !(
                  !!_vm.noDataText &&
                  (!_vm.data || _vm.data.length === 0)
                ),
                expression: "!(!!noDataText && (!data || data.length === 0))"
              }
            ],
            ref: "body",
            class: [_vm.prefixCls + "-body"],
            style: _vm.bodyStyle,
            on: { scroll: _vm.handleBodyScroll }
          },
          [
            _c("table-body", {
              ref: "tbody",
              attrs: {
                draggable: _vm.draggable,
                "prefix-cls": _vm.prefixCls,
                styleObject: _vm.tableStyle,
                columns: _vm.cloneColumns,
                data: _vm.rebuildData,
                "row-key": _vm.rowKey,
                "columns-width": _vm.columnsWidth,
                "obj-data": _vm.objData
              }
            })
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: !_vm.data || _vm.data.length === 0,
                expression: "!data || data.length === 0"
              }
            ],
            class: [_vm.prefixCls + "-tip"],
            style: _vm.bodyStyle,
            on: { scroll: _vm.handleBodyScroll }
          },
          [
            _c(
              "table",
              { attrs: { cellspacing: "0", cellpadding: "0", border: "0" } },
              [
                _c("tbody", [
                  _c("tr", [
                    _c(
                      "td",
                      {
                        style: {
                          height: _vm.bodyStyle.height,
                          width: this.headerWidth + "px"
                        }
                      },
                      [
                        !_vm.data || _vm.data.length === 0
                          ? _c("b-empty", [_vm._v(_vm._s(_vm.noDataText))])
                          : _vm._e()
                      ],
                      1
                    )
                  ])
                ])
              ]
            )
          ]
        ),
        _vm._v(" "),
        _vm.isLeftFixed
          ? _c(
              "div",
              { class: [_vm.prefixCls + "-fixed"], style: _vm.fixedTableStyle },
              [
                _vm.showHeader
                  ? _c(
                      "div",
                      { class: _vm.fixedHeaderClasses },
                      [
                        _c("table-head", {
                          attrs: {
                            fixed: "left",
                            "prefix-cls": _vm.prefixCls,
                            styleObject: _vm.fixedTableStyle,
                            columns: _vm.leftFixedColumns,
                            "column-rows": _vm.columnRows,
                            "fixed-column-rows": _vm.leftFixedColumnRows,
                            "obj-data": _vm.objData,
                            "columns-width": _vm.columnsWidth,
                            data: _vm.rebuildData
                          }
                        })
                      ],
                      1
                    )
                  : _vm._e(),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    ref: "fixedBody",
                    class: [_vm.prefixCls + "-fixed-body"],
                    style: _vm.fixedBodyStyle,
                    on: {
                      mousewheel: _vm.handleFixedMousewheel,
                      DOMMouseScroll: _vm.handleFixedMousewheel
                    }
                  },
                  [
                    _c("table-body", {
                      attrs: {
                        fixed: "left",
                        draggable: _vm.draggable,
                        "prefix-cls": _vm.prefixCls,
                        styleObject: _vm.fixedTableStyle,
                        columns: _vm.leftFixedColumns,
                        data: _vm.rebuildData,
                        "row-key": _vm.rowKey,
                        "columns-width": _vm.columnsWidth,
                        "obj-data": _vm.objData
                      }
                    })
                  ],
                  1
                )
              ]
            )
          : _vm._e(),
        _vm._v(" "),
        _vm.isRightFixed
          ? _c(
              "div",
              {
                class: [_vm.prefixCls + "-fixed-right"],
                style: _vm.fixedRightTableStyle
              },
              [
                _vm.showHeader
                  ? _c(
                      "div",
                      { class: _vm.fixedHeaderClasses },
                      [
                        _c("table-head", {
                          attrs: {
                            fixed: "right",
                            "prefix-cls": _vm.prefixCls,
                            styleObject: _vm.fixedRightTableStyle,
                            columns: _vm.rightFixedColumns,
                            "column-rows": _vm.columnRows,
                            "fixed-column-rows": _vm.rightFixedColumnRows,
                            "obj-data": _vm.objData,
                            "columns-width": _vm.columnsWidth,
                            data: _vm.rebuildData
                          }
                        })
                      ],
                      1
                    )
                  : _vm._e(),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    ref: "fixedRightBody",
                    class: [_vm.prefixCls + "-fixed-body"],
                    style: _vm.fixedBodyStyle,
                    on: {
                      mousewheel: _vm.handleFixedMousewheel,
                      DOMMouseScroll: _vm.handleFixedMousewheel
                    }
                  },
                  [
                    _c("table-body", {
                      attrs: {
                        fixed: "right",
                        draggable: _vm.draggable,
                        "prefix-cls": _vm.prefixCls,
                        styleObject: _vm.fixedRightTableStyle,
                        columns: _vm.rightFixedColumns,
                        data: _vm.rebuildData,
                        "row-key": _vm.rowKey,
                        "columns-width": _vm.columnsWidth,
                        "obj-data": _vm.objData
                      }
                    })
                  ],
                  1
                )
              ]
            )
          : _vm._e(),
        _vm._v(" "),
        _vm.isRightFixed
          ? _c("div", {
              class: [_vm.prefixCls + "-fixed-right-header"],
              style: _vm.fixedRightHeaderStyle
            })
          : _vm._e(),
        _vm._v(" "),
        _vm.showSlotFooter
          ? _c(
              "div",
              { ref: "footer", class: [_vm.prefixCls + "-footer"] },
              [_vm._t("footer")],
              2
            )
          : _vm._e()
      ]),
      _vm._v(" "),
      _vm.loading
        ? _c(
            "b-loading",
            { attrs: { fix: "", size: "large" } },
            [_vm._t("loading")],
            2
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }