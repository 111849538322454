var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "span",
    [
      _vm.datePanelLabel
        ? _c(
            "span",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value:
                    _vm.datePanelLabel.labels[0].type === "year" ||
                    _vm.currentView === "date",
                  expression:
                    "datePanelLabel.labels[0].type === 'year' || currentView === 'date'"
                }
              ],
              class: [_vm.datePrefixCls + "-header-label"],
              on: { click: _vm.datePanelLabel.labels[0].handler }
            },
            [_vm._v(_vm._s(_vm.datePanelLabel.labels[0].label))]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.datePanelLabel && _vm.currentView === "date"
        ? [_vm._v(_vm._s(_vm.datePanelLabel.separator))]
        : _vm._e(),
      _vm._v(" "),
      _vm.datePanelLabel
        ? _c(
            "span",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value:
                    _vm.datePanelLabel.labels[1].type === "year" ||
                    _vm.currentView === "date",
                  expression:
                    "datePanelLabel.labels[1].type === 'year' || currentView === 'date'"
                }
              ],
              class: [_vm.datePrefixCls + "-header-label"],
              on: { click: _vm.datePanelLabel.labels[1].handler }
            },
            [_vm._v(_vm._s(_vm.datePanelLabel.labels[1].label))]
          )
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }