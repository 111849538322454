var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.uiLoading
    ? _c(
        "div",
        _vm._b(
          { class: ["bin-skeleton", _vm.animation ? "is-animated" : ""] },
          "div",
          _vm.$attrs,
          false
        ),
        [
          _vm._l(_vm.count, function(i) {
            return [
              _vm.loading
                ? _vm._t("template", [
                    _c("b-skeleton-item", {
                      key: i + "-first",
                      staticClass: "is-first",
                      attrs: { variant: "p" }
                    }),
                    _vm._v(" "),
                    _vm._l(_vm.rows, function(item) {
                      return _c("b-skeleton-item", {
                        key: item,
                        class: {
                          "bin-skeleton__paragraph": true,
                          "is-last": item === _vm.rows && _vm.rows > 1
                        },
                        attrs: { variant: "p" }
                      })
                    })
                  ])
                : _vm._e()
            ]
          })
        ],
        2
      )
    : _c("div", [_vm._t("default", null, null, _vm.$attrs)], 2)
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }