var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "table",
    {
      style: _vm.styleObject,
      attrs: { cellspacing: "0", cellpadding: "0", border: "0" }
    },
    [
      _c(
        "colgroup",
        _vm._l(_vm.columns, function(column, index) {
          return _c("col", {
            key: "group-" + index,
            attrs: { width: _vm.setCellWidth(column) }
          })
        }),
        0
      ),
      _vm._v(" "),
      _c(
        "tbody",
        { class: [_vm.prefixCls + "-tbody"] },
        [
          _vm._l(_vm.data, function(row, index) {
            var _obj
            return [
              _c(
                "table-tr",
                {
                  key: _vm.rowKey ? row._rowKey : "tr-" + index,
                  attrs: {
                    draggable: _vm.draggable,
                    row: row,
                    "prefix-cls": _vm.prefixCls
                  },
                  nativeOn: {
                    mouseenter: function($event) {
                      $event.stopPropagation()
                      return _vm.handleMouseIn(row._index)
                    },
                    mouseleave: function($event) {
                      $event.stopPropagation()
                      return _vm.handleMouseOut(row._index)
                    },
                    click: function($event) {
                      return _vm.clickCurrentRow(row._index)
                    },
                    dblclick: function($event) {
                      $event.stopPropagation()
                      return _vm.dblclickCurrentRow(row._index)
                    }
                  }
                },
                [
                  _vm._l(_vm.columns, function(column, colIndex) {
                    return [
                      _vm.showWithSpan(row, column, index, colIndex)
                        ? _c(
                            "td",
                            _vm._b(
                              {
                                key: column._columnKey,
                                class: _vm.alignCls(column, row)
                              },
                              "td",
                              _vm.getSpan(row, column, index, colIndex),
                              false
                            ),
                            [
                              _c("table-cell", {
                                key: column._columnKey,
                                attrs: {
                                  fixed: _vm.fixed,
                                  "prefix-cls": _vm.prefixCls,
                                  row: row,
                                  column: column,
                                  "natural-index": index,
                                  index: row._index,
                                  checked: _vm.rowChecked(row._index),
                                  disabled: _vm.rowDisabled(row._index),
                                  expanded: _vm.rowExpanded(row._index)
                                }
                              })
                            ],
                            1
                          )
                        : _vm._e()
                    ]
                  })
                ],
                2
              ),
              _vm._v(" "),
              _vm.rowExpanded(row._index)
                ? _c(
                    "tr",
                    {
                      key: row._index,
                      class:
                        ((_obj = {}),
                        (_obj[_vm.prefixCls + "-expanded-hidden"] = _vm.fixed),
                        _obj)
                    },
                    [
                      _c(
                        "td",
                        {
                          class: _vm.prefixCls + "-expanded-cell",
                          attrs: { colspan: _vm.columns.length }
                        },
                        [
                          _c("expand", {
                            key: _vm.rowKey ? row._rowKey : index,
                            attrs: {
                              row: row,
                              render: _vm.expandRender,
                              index: row._index
                            }
                          })
                        ],
                        1
                      )
                    ]
                  )
                : _vm._e()
            ]
          })
        ],
        2
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }