<template>
  <div class="bin-button-group">
    <slot></slot>
  </div>
</template>
<script>
export default {
  name: 'BButtonGroup'
}
</script>
