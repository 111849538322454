var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "ul",
    { class: [_vm.prefixCls + "-list"] },
    _vm._l(_vm.files, function(file, index) {
      return _c(
        "li",
        {
          key: index,
          class: _vm.fileCls(file),
          on: {
            click: function($event) {
              return _vm.handleClick(file)
            }
          }
        },
        [
          _c(
            "span",
            {
              on: {
                click: function($event) {
                  return _vm.handlePreview(file)
                }
              }
            },
            [
              _c("b-icon", { attrs: { name: _vm.format(file) } }),
              _vm._v(" " + _vm._s(file.name) + "\n    ")
            ],
            1
          ),
          _vm._v(" "),
          _c("b-icon", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: file.status === "finished",
                expression: "file.status === 'finished'"
              }
            ],
            class: [_vm.prefixCls + "-list-remove"],
            attrs: { name: "ios-close" },
            nativeOn: {
              click: function($event) {
                return _vm.handleRemove(file)
              }
            }
          }),
          _vm._v(" "),
          _c(
            "transition",
            { attrs: { name: "fade" } },
            [
              file.showProgress
                ? _c("b-progress", {
                    attrs: {
                      "stroke-width": 2,
                      percent: _vm.parsePercentage(file.percentage),
                      status:
                        file.status === "finished" && file.showProgress
                          ? "success"
                          : null
                    }
                  })
                : _vm._e()
            ],
            1
          )
        ],
        1
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }