var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("span", { staticClass: "bin-breadcrumb__item" }, [
    _c(
      "span",
      {
        ref: "link",
        class: ["bin-breadcrumb__inner", _vm.to ? "is-link" : ""],
        attrs: { role: "link" }
      },
      [_vm._t("default")],
      2
    ),
    _vm._v(" "),
    _vm.separatorIcon
      ? _c("i", {
          staticClass: "bin-separator icon",
          class: ["iconfont", "icon-" + _vm.separatorIcon]
        })
      : _c(
          "span",
          { staticClass: "bin-separator", attrs: { role: "presentation" } },
          [_vm._v(_vm._s(_vm.separator))]
        )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }