var render = function() {
  var _obj, _obj$1, _obj$2
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "bin-splitter-container clear-fix",
      style: { cursor: _vm.cursor, userSelect: _vm.userSelect },
      on: { mouseup: _vm.onMouseUp, mousemove: _vm.onMouseMove }
    },
    [
      _c(
        "pane",
        {
          staticClass: "bin-splitter-pane splitter-left",
          style: ((_obj = {}), (_obj[_vm.type] = _vm.percent + "%"), _obj),
          attrs: { split: _vm.split }
        },
        [_vm._t("left")],
        2
      ),
      _vm._v(" "),
      _c("resizer", {
        style:
          ((_obj$1 = {}), (_obj$1[_vm.resizeType] = _vm.percent + "%"), _obj$1),
        attrs: { className: _vm.className, split: _vm.split },
        nativeOn: {
          mousedown: function($event) {
            return _vm.onMouseDown($event)
          },
          click: function($event) {
            return _vm.onClick($event)
          }
        }
      }),
      _vm._v(" "),
      _c(
        "pane",
        {
          staticClass: "bin-splitter-pane splitter-right",
          style:
            ((_obj$2 = {}),
            (_obj$2[_vm.type] = 100 - _vm.percent + "%"),
            _obj$2),
          attrs: { split: _vm.split }
        },
        [_vm._t("right")],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }