var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("transition", { attrs: { name: "fade-in" } }, [
    !_vm.closed
      ? _c("div", { class: _vm.wrapClasses }, [
          _vm.showIcon
            ? _c(
                "span",
                { class: _vm.iconClasses },
                [
                  _vm._t("icon", [
                    _c("b-icon", { attrs: { name: _vm.iconType } })
                  ])
                ],
                2
              )
            : _vm._e(),
          _vm._v(" "),
          _c("span", { class: _vm.messageClasses }, [_vm._t("default")], 2),
          _vm._v(" "),
          _c("span", { class: _vm.descClasses }, [_vm._t("desc")], 2),
          _vm._v(" "),
          _vm.closable
            ? _c(
                "a",
                { class: _vm.closeClasses, on: { click: _vm.close } },
                [
                  _vm._t("close", [
                    _c("b-icon", { attrs: { name: "ios-close" } })
                  ])
                ],
                2
              )
            : _vm._e()
        ])
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }