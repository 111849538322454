export default {
  data() {
    return {
      prefixCls: 'bin-color-picker',
      inputPrefixCls: 'bin-input',
      iconPrefixCls: 'bin-icon',
      transferPrefixCls: 'bin-transfer'
    }
  }
}
