var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      class: [_vm.prefixCls + "-hue"],
      attrs: { tabindex: "0" },
      on: {
        click: function($event) {
          return _vm.$el.focus()
        },
        keydown: [
          function($event) {
            if (
              !$event.type.indexOf("key") &&
              _vm._k($event.keyCode, "esc", 27, $event.key, ["Esc", "Escape"])
            ) {
              return null
            }
            return _vm.handleEscape($event)
          },
          function($event) {
            if (
              !$event.type.indexOf("key") &&
              _vm._k($event.keyCode, "left", 37, $event.key, [
                "Left",
                "ArrowLeft"
              ])
            ) {
              return null
            }
            if ("button" in $event && $event.button !== 0) {
              return null
            }
            return _vm.handleLeft($event)
          },
          function($event) {
            if (
              !$event.type.indexOf("key") &&
              _vm._k($event.keyCode, "right", 39, $event.key, [
                "Right",
                "ArrowRight"
              ])
            ) {
              return null
            }
            if ("button" in $event && $event.button !== 2) {
              return null
            }
            return _vm.handleRight($event)
          },
          function($event) {
            if (
              !$event.type.indexOf("key") &&
              _vm._k($event.keyCode, "up", 38, $event.key, ["Up", "ArrowUp"])
            ) {
              return null
            }
            return _vm.handleUp($event)
          },
          function($event) {
            if (
              !$event.type.indexOf("key") &&
              _vm._k($event.keyCode, "down", 40, $event.key, [
                "Down",
                "ArrowDown"
              ])
            ) {
              return null
            }
            return _vm.handleDown($event)
          }
        ]
      }
    },
    [
      _c(
        "div",
        {
          ref: "container",
          class: [_vm.prefixCls + "-hue-container"],
          on: {
            mousedown: _vm.handleMouseDown,
            touchmove: _vm.handleChange,
            touchstart: _vm.handleChange
          }
        },
        [
          _c(
            "div",
            {
              class: [_vm.prefixCls + "-hue-pointer"],
              style: { top: 0, left: _vm.percent + "%" }
            },
            [_c("div", { class: [_vm.prefixCls + "-hue-picker"] })]
          )
        ]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }