var render = function() {
  var _obj
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "click-outside",
          rawName: "v-click-outside",
          value: _vm.handleClose,
          expression: "handleClose"
        }
      ],
      class: _vm.classes
    },
    [
      _c(
        "div",
        {
          ref: "reference",
          class: [_vm.prefixCls + "-rel"],
          on: { click: _vm.toggleOpen }
        },
        [
          _c("input", {
            attrs: { type: "hidden", name: _vm.name },
            domProps: { value: _vm.currentValue }
          }),
          _vm._v(" "),
          _vm._t("default", [
            _c("b-input", {
              ref: "input",
              attrs: {
                "element-id": _vm.elementId,
                readonly: !_vm.filterable,
                disabled: _vm.disabled,
                value: _vm.displayInputRender,
                size: _vm.size,
                placeholder: _vm.inputPlaceholder
              },
              on: { change: _vm.handleInput }
            }),
            _vm._v(" "),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.filterable && _vm.query === "",
                    expression: "filterable && query === ''"
                  }
                ],
                class: [_vm.prefixCls + "-label"],
                on: { click: _vm.handleFocus }
              },
              [_vm._v("\n        " + _vm._s(_vm.displayRender) + "\n      ")]
            ),
            _vm._v(" "),
            _c("b-icon", {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.showCloseIcon,
                  expression: "showCloseIcon"
                }
              ],
              class: [_vm.prefixCls + "-arrow"],
              attrs: { name: "ios-close-circle" },
              nativeOn: {
                click: function($event) {
                  $event.stopPropagation()
                  return _vm.clearSelect($event)
                }
              }
            }),
            _vm._v(" "),
            _c("b-icon", {
              class: [_vm.prefixCls + "-arrow"],
              attrs: { name: "ios-arrow-down" }
            })
          ])
        ],
        2
      ),
      _vm._v(" "),
      _c(
        "transition",
        { attrs: { name: "zoom-in-top" } },
        [
          _c(
            "drop",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.visible,
                  expression: "visible"
                },
                { name: "transfer-dom", rawName: "v-transfer-dom" }
              ],
              ref: "drop",
              class:
                ((_obj = {}),
                (_obj[_vm.prefixCls + "-transfer"] = _vm.transfer),
                _obj),
              attrs: { "data-transfer": _vm.transfer, transfer: _vm.transfer }
            },
            [
              _c(
                "div",
                [
                  _c("cas-panel", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value:
                          !_vm.filterable ||
                          (_vm.filterable && _vm.query === ""),
                        expression:
                          "!filterable || (filterable && query === '')"
                      }
                    ],
                    ref: "caspanel",
                    attrs: {
                      "prefix-cls": _vm.prefixCls,
                      data: _vm.data,
                      disabled: _vm.disabled,
                      "change-on-select": _vm.changeOnSelect,
                      trigger: _vm.trigger
                    }
                  }),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value:
                            _vm.filterable &&
                            _vm.query !== "" &&
                            _vm.querySelections.length,
                          expression:
                            "filterable && query !== '' && querySelections.length"
                        }
                      ],
                      class: [_vm.prefixCls + "-dropdown"]
                    },
                    [
                      _c(
                        "ul",
                        { class: [_vm.selectPrefixCls + "-dropdown-list"] },
                        _vm._l(_vm.querySelections, function(item, index) {
                          var _obj
                          return _c("li", {
                            key: index,
                            class: [
                              _vm.selectPrefixCls + "-item",
                              ((_obj = {}),
                              (_obj[_vm.selectPrefixCls + "-item-disabled"] =
                                item.disabled),
                              _obj)
                            ],
                            domProps: { innerHTML: _vm._s(item.display) },
                            on: {
                              click: function($event) {
                                return _vm.handleSelectItem(index)
                              }
                            }
                          })
                        }),
                        0
                      )
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "ul",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value:
                            (_vm.filterable &&
                              _vm.query !== "" &&
                              !_vm.querySelections.length) ||
                            !_vm.data.length,
                          expression:
                            "(filterable && query !== '' && !querySelections.length) || !data.length"
                        }
                      ],
                      class: [_vm.prefixCls + "-not-found-tip"]
                    },
                    [_c("li", [_vm._v(_vm._s(_vm.notFoundText))])]
                  )
                ],
                1
              )
            ]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }