var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "bin-message", style: _vm.wrapStyles },
    _vm._l(_vm.notices, function(notice) {
      return _c("notice", {
        key: notice.name,
        attrs: {
          type: notice.type,
          render: notice.render,
          content: notice.content,
          closable: notice.closable,
          "transition-name": notice.transitionName,
          duration: notice.duration,
          name: notice.name,
          "on-close": notice.onClose
        }
      })
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }