var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "bin-empty bin-empty-normal" }, [
    _c(
      "div",
      { staticClass: "bin-empty-image" },
      [
        _vm._t("icon", [
          _c(
            "svg",
            {
              staticClass: "bin-empty-img-simple",
              attrs: {
                width: "64",
                height: "41",
                viewBox: "0 0 64 41",
                xmlns: "http://www.w3.org/2000/svg"
              }
            },
            [
              _c(
                "g",
                {
                  attrs: {
                    transform: "translate(0 1)",
                    fill: "none",
                    "fill-rule": "evenodd"
                  }
                },
                [
                  _c("ellipse", {
                    staticClass: "bin-empty-img-simple-ellipse",
                    attrs: { cx: "32", cy: "33", rx: "32", ry: "7" }
                  }),
                  _vm._v(" "),
                  _c(
                    "g",
                    {
                      staticClass: "bin-empty-img-simple-g",
                      attrs: { "fill-rule": "nonzero" }
                    },
                    [
                      _c("path", {
                        attrs: {
                          d:
                            "M55 12.76L44.854 1.258C44.367.474 43.656 0 42.907 0H21.093c-.749 0-1.46.474-1.947 1.257L9 12.761V22h46v-9.24z"
                        }
                      }),
                      _vm._v(" "),
                      _c("path", {
                        staticClass: "bin-empty-img-simple-path",
                        attrs: {
                          d:
                            "M41.613 15.931c0-1.605.994-2.93 2.227-2.931H55v18.137C55 33.26 53.68 35 52.05 35h-40.1C10.32 35 9 33.259 9 31.137V13h11.16c1.233 0 2.227 1.323 2.227 2.928v.022c0 1.605 1.005 2.901 2.237 2.901h14.752c1.232 0 2.237-1.308 2.237-2.913v-.007z"
                        }
                      })
                    ]
                  )
                ]
              )
            ]
          )
        ])
      ],
      2
    ),
    _vm._v(" "),
    _c(
      "p",
      { staticClass: "bin-empty-description" },
      [_vm._t("default", [_vm._v("暂无数据")])],
      2
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }