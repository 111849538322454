var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "li",
    { class: _vm.classes },
    [
      _vm._v("\n  " + _vm._s(_vm.data.label) + "\n  "),
      _vm.showArrow
        ? _c("b-icon", { attrs: { name: "ios-arrow-forward" } })
        : _vm._e(),
      _vm._v(" "),
      _vm.showLoading
        ? _c("b-icon", {
            staticClass: "bin-cascade-menu-item-loading",
            attrs: { name: "loading" }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }