var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      class: [_vm.prefixCls],
      on: {
        mouseenter: _vm.handleShowPopper,
        mouseleave: _vm.handleClosePopper
      }
    },
    [
      _c(
        "div",
        { ref: "reference", class: [_vm.prefixCls + "-rel"] },
        [_vm._t("default")],
        2
      ),
      _vm._v(" "),
      _c("transition", { attrs: { name: "fade-in" } }, [
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: !_vm.disabled && (_vm.visible || _vm.always),
                expression: "!disabled && (visible || always)"
              }
            ],
            ref: "popper",
            class: [_vm.prefixCls + "-popper", _vm.prefixCls + "-" + _vm.theme],
            style: _vm.dropStyles,
            on: {
              mouseenter: _vm.handleShowPopper,
              mouseleave: _vm.handleClosePopper
            }
          },
          [
            _c("div", { class: [_vm.prefixCls + "-content"] }, [
              _c("div", { class: [_vm.prefixCls + "-arrow"] }),
              _vm._v(" "),
              _c(
                "div",
                { class: _vm.innerClasses, style: _vm.innerStyles },
                [_vm._t("content", [_vm._v(_vm._s(_vm.content))])],
                2
              )
            ])
          ]
        )
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }