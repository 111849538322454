var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.modal
    ? _c(
        "div",
        { staticClass: "bin-icon-select" },
        [
          _c("b-input", {
            attrs: {
              size: _vm.size,
              value: _vm.selectIcon,
              placeholder: _vm.placeholder,
              prefix: _vm.selectIcon,
              disabled: _vm.disabled,
              readonly: "",
              clearable: ""
            },
            on: {
              clear: function($event) {
                return _vm.selectChange("")
              }
            }
          }),
          _vm._v(" "),
          _c(
            "b-button",
            {
              attrs: {
                disabled: _vm.disabled,
                type: _vm.buttonType,
                size: _vm.size,
                plain: ""
              },
              on: {
                click: function($event) {
                  _vm.iconDialog = true
                }
              }
            },
            [_vm._v("选择")]
          ),
          _vm._v(" "),
          _c(
            "b-modal",
            {
              attrs: {
                title: "选择图标",
                width: "1000",
                "body-styles": { padding: 0 },
                "footer-hide": "",
                "stop-remove-scroll": _vm.stopRemoveScroll
              },
              model: {
                value: _vm.iconDialog,
                callback: function($$v) {
                  _vm.iconDialog = $$v
                },
                expression: "iconDialog"
              }
            },
            [
              _c(
                "div",
                {
                  staticStyle: {
                    height: "500px",
                    overflow: "auto",
                    padding: "12px 7px"
                  }
                },
                [
                  _c(
                    "div",
                    { staticClass: "icon-wrap" },
                    _vm._l(_vm.icons, function(icon) {
                      return _c(
                        "div",
                        {
                          key: icon,
                          staticClass: "icon-item",
                          attrs: { title: icon },
                          on: {
                            click: function($event) {
                              return _vm.selectChange(icon)
                            }
                          }
                        },
                        [_c("b-icon", { attrs: { name: icon } })],
                        1
                      )
                    }),
                    0
                  )
                ]
              )
            ]
          )
        ],
        1
      )
    : _c(
        "b-select",
        {
          attrs: {
            disabled: _vm.disabled,
            value: _vm.selectIcon,
            prefix: _vm.selectIcon,
            filterable: "",
            clearable: "",
            placeholder: _vm.placeholder
          },
          on: {
            change: _vm.selectChange,
            clear: function($event) {
              return _vm.selectChange("")
            }
          }
        },
        _vm._l(_vm.icons, function(item) {
          return _c(
            "b-option",
            { key: item, attrs: { value: item } },
            [
              _c("b-icon", {
                staticStyle: { "margin-right": "8px" },
                attrs: { name: item }
              }),
              _vm._v(_vm._s(item) + "\n  ")
            ],
            1
          )
        }),
        1
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }