var render = function() {
  var _obj
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "click-outside",
          rawName: "v-click-outside",
          value: _vm.handleClose,
          expression: "handleClose"
        }
      ],
      class: _vm.wrapperClasses
    },
    [
      _c(
        "div",
        { ref: "reference", class: [_vm.prefixCls + "-rel"] },
        [
          _vm._t("default", [
            _c(
              "b-input",
              {
                key: _vm.forceInputRerender,
                ref: "input",
                class: [_vm.prefixCls + "-editor"],
                attrs: {
                  "element-id": _vm.elementId,
                  readonly: !_vm.editable || _vm.readonly,
                  disabled: _vm.disabled,
                  size: _vm.size,
                  placeholder: _vm.placeholder,
                  value: _vm.visualValue,
                  name: _vm.name
                },
                on: {
                  "input-change": _vm.handleInputChange,
                  focus: _vm.handleFocus,
                  blur: _vm.handleBlur
                },
                nativeOn: {
                  click: function($event) {
                    return _vm.handleFocus($event)
                  },
                  keydown: function($event) {
                    return _vm.handleKeydown($event)
                  },
                  mouseenter: function($event) {
                    return _vm.handleInputMouseenter($event)
                  },
                  mouseleave: function($event) {
                    return _vm.handleInputMouseleave($event)
                  }
                }
              },
              [
                _c("b-icon", {
                  attrs: { slot: "suffix", name: _vm.arrowType },
                  nativeOn: {
                    click: function($event) {
                      $event.stopPropagation()
                      $event.preventDefault()
                      return _vm.handleIconClick($event)
                    }
                  },
                  slot: "suffix"
                })
              ],
              1
            )
          ])
        ],
        2
      ),
      _vm._v(" "),
      _c(
        "transition",
        { attrs: { name: "zoom-in-top" } },
        [
          _c(
            "drop",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.opened,
                  expression: "opened"
                },
                { name: "transfer-dom", rawName: "v-transfer-dom" }
              ],
              ref: "drop",
              class:
                ((_obj = {}),
                (_obj[_vm.prefixCls + "-transfer"] = _vm.appendToBody),
                _obj),
              attrs: {
                placement: _vm.placement,
                "data-transfer": _vm.appendToBody,
                transfer: _vm.appendToBody
              },
              nativeOn: {
                click: function($event) {
                  return _vm.handleTransferClick($event)
                }
              }
            },
            [
              _c(
                "div",
                [
                  _c(
                    _vm.panel,
                    _vm._b(
                      {
                        ref: "pickerPanel",
                        tag: "component",
                        attrs: {
                          visible: _vm.visible,
                          showTime:
                            _vm.type === "datetime" ||
                            _vm.type === "datetimerange",
                          confirm: _vm.isConfirm,
                          selectionMode: _vm.selectionMode,
                          steps: _vm.steps,
                          format: _vm.format,
                          value: _vm.internalValue,
                          "start-date": _vm.startDate,
                          "split-panels": _vm.splitPanels,
                          "show-week-numbers": _vm.showWeekNumbers,
                          "picker-type": _vm.type,
                          multiple: _vm.multiple,
                          "focused-date": _vm.focusedDate,
                          "time-picker-options": _vm.timePickerOptions
                        },
                        on: {
                          pick: _vm.onPick,
                          "pick-clear": _vm.handleClear,
                          "pick-success": _vm.onPickSuccess,
                          "pick-click": function($event) {
                            _vm.disableClickOutSide = true
                          },
                          "selection-mode-change": _vm.onSelectionModeChange
                        }
                      },
                      "component",
                      _vm.ownPickerProps,
                      false
                    )
                  )
                ],
                1
              )
            ]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }