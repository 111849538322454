var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "li",
    {
      class: _vm.classes,
      on: {
        click: function($event) {
          $event.stopPropagation()
          return _vm.select($event)
        },
        mousedown: function($event) {
          $event.preventDefault()
        }
      }
    },
    [_vm._t("default", [_vm._v(_vm._s(_vm.showLabel))])],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }