var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("transition", { attrs: { name: "back-top-fade" } }, [
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.backTop,
            expression: "backTop"
          }
        ],
        class: _vm.classes,
        style: _vm.styles,
        on: { click: _vm.back }
      },
      [
        _vm._t("default", [
          _c(
            "div",
            { class: _vm.innerClasses },
            [_c("b-icon", { attrs: { name: "md-arrow-dropup", size: "24" } })],
            1
          )
        ])
      ],
      2
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }