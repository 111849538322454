var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "bin-rate",
      attrs: {
        role: "slider",
        "aria-valuenow": _vm.currentValue,
        "aria-valuetext": _vm.text,
        "aria-valuemin": "0",
        "aria-valuemax": _vm.max,
        tabindex: "0"
      },
      on: { keydown: _vm.handleKey }
    },
    [
      _vm._l(_vm.max, function(item, key) {
        return _c(
          "span",
          {
            key: key,
            staticClass: "bin-rate__item",
            style: { cursor: _vm.rateDisabled ? "auto" : "pointer" },
            on: {
              mousemove: function($event) {
                return _vm.setCurrentValue(item, $event)
              },
              mouseleave: _vm.resetCurrentValue,
              click: function($event) {
                return _vm.selectValue(item)
              }
            }
          },
          [
            _c(
              "i",
              {
                staticClass: "bin-rate__icon iconfont",
                class: [
                  _vm.classes[item - 1],
                  { hover: _vm.hoverIndex === item }
                ],
                style: _vm.getIconStyle(item)
              },
              [
                _vm.showDecimalIcon(item)
                  ? _c("i", {
                      staticClass: "bin-rate__decimal iconfont",
                      class: _vm.decimalIconClass,
                      style: _vm.decimalStyle
                    })
                  : _vm._e()
              ]
            )
          ]
        )
      }),
      _vm._v(" "),
      _vm.showText || _vm.showScore
        ? _c(
            "span",
            { staticClass: "bin-rate__text", style: { color: _vm.textColor } },
            [_vm._v(_vm._s(_vm.text))]
          )
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }