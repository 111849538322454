var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "bin-modal" },
    [
      _c("transition", { attrs: { name: "fade-in-linear" } }, [
        _vm.showMask
          ? _c("div", {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.visible,
                  expression: "visible"
                }
              ],
              staticClass: "bin-modal-mask",
              style: _vm.wrapStyles,
              on: {
                click: function($event) {
                  $event.stopPropagation()
                  return _vm.handleMask($event)
                }
              }
            })
          : _vm._e()
      ]),
      _vm._v(" "),
      _c(
        "div",
        {
          class: _vm.wrapClasses,
          style: _vm.wrapStyles,
          on: {
            click: function($event) {
              $event.stopPropagation()
              return _vm.handleWrapClick($event)
            }
          }
        },
        [
          _c(
            "transition",
            {
              attrs: {
                name: _vm.transitionName ? _vm.transitionName : "modal-fade"
              },
              on: {
                "after-enter": _vm.animationEnter,
                "after-leave": _vm.animationFinish
              }
            },
            [
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.visible,
                      expression: "visible"
                    }
                  ],
                  class: _vm.classes,
                  style: _vm.mainStyles
                },
                [
                  _c(
                    "div",
                    {
                      ref: "content",
                      class: _vm.contentClasses,
                      style: _vm.contentStyles,
                      on: { click: _vm.handleClickModal }
                    },
                    [
                      _vm.closable
                        ? _c(
                            "a",
                            {
                              class: [_vm.prefixCls + "-close"],
                              on: { click: _vm.close }
                            },
                            [
                              _vm._t("close", [
                                _c("b-icon", { attrs: { name: "close" } })
                              ])
                            ],
                            2
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.showHead
                        ? _c(
                            "div",
                            {
                              class: [_vm.prefixCls + "-header"],
                              on: { mousedown: _vm.handleMoveStart }
                            },
                            [
                              _vm._t("header", [
                                _c(
                                  "div",
                                  { class: [_vm.prefixCls + "-header-inner"] },
                                  [_vm._v(_vm._s(_vm.title))]
                                )
                              ])
                            ],
                            2
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          class: [_vm.prefixCls + "-body"],
                          style: _vm.bodyStyles
                        },
                        [_vm._t("default")],
                        2
                      ),
                      _vm._v(" "),
                      !_vm.footerHide
                        ? _c(
                            "div",
                            { class: [_vm.prefixCls + "-footer"] },
                            [
                              _vm._t("footer", [
                                _c("b-button", { on: { click: _vm.cancel } }, [
                                  _vm._v(_vm._s(_vm.cancelText))
                                ]),
                                _vm._v(" "),
                                _c(
                                  "b-button",
                                  {
                                    attrs: {
                                      type: "primary",
                                      loading: _vm.buttonLoading
                                    },
                                    on: { click: _vm.ok }
                                  },
                                  [_vm._v(_vm._s(_vm.okText))]
                                )
                              ])
                            ],
                            2
                          )
                        : _vm._e()
                    ]
                  )
                ]
              )
            ]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }