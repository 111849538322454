var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { class: _vm.anchorLinkClasses },
    [
      _c(
        "a",
        {
          class: _vm.linkTitleClasses,
          style: _vm.activeColor,
          attrs: { href: _vm.href, "data-href": _vm.href, title: _vm.title },
          on: {
            click: function($event) {
              $event.preventDefault()
              return _vm.goAnchor($event)
            }
          }
        },
        [_vm._v(_vm._s(_vm.title))]
      ),
      _vm._v(" "),
      _vm._t("default")
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }