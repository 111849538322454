var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.simple
    ? _c("ul", { class: _vm.simpleWrapClasses, style: _vm.styles }, [
        _c(
          "li",
          {
            class: _vm.prevClasses,
            attrs: { title: "上一页" },
            on: { click: _vm.prev }
          },
          [_vm._m(0)]
        ),
        _vm._v(" "),
        _c(
          "li",
          {
            class: _vm.simplePagerClasses,
            attrs: { title: _vm.currentPage + "/" + _vm.allPages }
          },
          [
            _c("label", [
              _c("input", {
                attrs: {
                  type: "text",
                  autocomplete: "off",
                  spellcheck: "false"
                },
                domProps: { value: _vm.currentPage },
                on: {
                  keydown: _vm.keyDown,
                  keyup: _vm.keyUp,
                  change: _vm.keyUp
                }
              })
            ]),
            _vm._v(" "),
            _c("span", [_vm._v("/")]),
            _vm._v("\n    " + _vm._s(_vm.allPages) + "\n  ")
          ]
        ),
        _vm._v(" "),
        _c(
          "li",
          {
            class: _vm.nextClasses,
            attrs: { title: "下一页" },
            on: { click: _vm.next }
          },
          [_vm._m(1)]
        )
      ])
    : _c(
        "ul",
        { class: _vm.wrapClasses, style: _vm.styles },
        [
          _vm.showTotal
            ? _c("li", { class: [_vm.prefixCls + "-total"] }, [
                _c(
                  "span",
                  [
                    _vm._t("default", [
                      _vm._v("共 " + _vm._s(_vm.total) + " 条")
                    ])
                  ],
                  2
                )
              ])
            : _vm._e(),
          _vm._v(" "),
          _c(
            "li",
            {
              class: _vm.prevClasses,
              attrs: { title: "上一页" },
              on: { click: _vm.prev }
            },
            [
              _c(
                "a",
                [
                  _vm.prevText !== ""
                    ? [_vm._v(_vm._s(_vm.prevText))]
                    : _c("i", { staticClass: "iconfont icon-ios-arrow-back" })
                ],
                2
              )
            ]
          ),
          _vm._v(" "),
          _c(
            "li",
            {
              class: _vm.firstPageClasses,
              attrs: { title: "1" },
              on: {
                click: function($event) {
                  return _vm.changePage(1)
                }
              }
            },
            [_c("a", [_vm._v("1")])]
          ),
          _vm._v(" "),
          _vm.currentPage > 5
            ? _c(
                "li",
                {
                  class: [_vm.prefixCls + "-item-jump-prev"],
                  attrs: { title: "向前5页" },
                  on: { click: _vm.fastPrev }
                },
                [_vm._m(2)]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.currentPage === 5
            ? _c(
                "li",
                {
                  class: [_vm.prefixCls + "-item"],
                  attrs: { title: _vm.currentPage - 3 },
                  on: {
                    click: function($event) {
                      return _vm.changePage(_vm.currentPage - 3)
                    }
                  }
                },
                [_c("a", [_vm._v(_vm._s(_vm.currentPage - 3))])]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.currentPage - 2 > 1
            ? _c(
                "li",
                {
                  class: [_vm.prefixCls + "-item"],
                  attrs: { title: _vm.currentPage - 2 },
                  on: {
                    click: function($event) {
                      return _vm.changePage(_vm.currentPage - 2)
                    }
                  }
                },
                [_c("a", [_vm._v(_vm._s(_vm.currentPage - 2))])]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.currentPage - 1 > 1
            ? _c(
                "li",
                {
                  class: [_vm.prefixCls + "-item"],
                  attrs: { title: _vm.currentPage - 1 },
                  on: {
                    click: function($event) {
                      return _vm.changePage(_vm.currentPage - 1)
                    }
                  }
                },
                [_c("a", [_vm._v(_vm._s(_vm.currentPage - 1))])]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.currentPage !== 1 && _vm.currentPage !== _vm.allPages
            ? _c(
                "li",
                {
                  class: [
                    _vm.prefixCls + "-item",
                    _vm.prefixCls + "-item-active"
                  ],
                  attrs: { title: _vm.currentPage }
                },
                [_c("a", [_vm._v(_vm._s(_vm.currentPage))])]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.currentPage + 1 < _vm.allPages
            ? _c(
                "li",
                {
                  class: [_vm.prefixCls + "-item"],
                  attrs: { title: _vm.currentPage + 1 },
                  on: {
                    click: function($event) {
                      return _vm.changePage(_vm.currentPage + 1)
                    }
                  }
                },
                [_c("a", [_vm._v(_vm._s(_vm.currentPage + 1))])]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.currentPage + 2 < _vm.allPages
            ? _c(
                "li",
                {
                  class: [_vm.prefixCls + "-item"],
                  attrs: { title: _vm.currentPage + 2 },
                  on: {
                    click: function($event) {
                      return _vm.changePage(_vm.currentPage + 2)
                    }
                  }
                },
                [_c("a", [_vm._v(_vm._s(_vm.currentPage + 2))])]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.allPages - _vm.currentPage === 4
            ? _c(
                "li",
                {
                  class: [_vm.prefixCls + "-item"],
                  attrs: { title: _vm.currentPage + 3 },
                  on: {
                    click: function($event) {
                      return _vm.changePage(_vm.currentPage + 3)
                    }
                  }
                },
                [_c("a", [_vm._v(_vm._s(_vm.currentPage + 3))])]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.allPages - _vm.currentPage >= 5
            ? _c(
                "li",
                {
                  class: [_vm.prefixCls + "-item-jump-next"],
                  attrs: { title: "向后5页" },
                  on: { click: _vm.fastNext }
                },
                [_vm._m(3)]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.allPages > 1
            ? _c(
                "li",
                {
                  class: _vm.lastPageClasses,
                  attrs: { title: _vm.allPages },
                  on: {
                    click: function($event) {
                      return _vm.changePage(_vm.allPages)
                    }
                  }
                },
                [_c("a", [_vm._v(_vm._s(_vm.allPages))])]
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "li",
            {
              class: _vm.nextClasses,
              attrs: { title: "下一页" },
              on: { click: _vm.next }
            },
            [
              _c(
                "a",
                [
                  _vm.nextText !== ""
                    ? [_vm._v(_vm._s(_vm.nextText))]
                    : _c("i", {
                        staticClass: "iconfont icon-ios-arrow-forward"
                      })
                ],
                2
              )
            ]
          ),
          _vm._v(" "),
          _c("options", {
            attrs: {
              "show-sizer": _vm.showSizer,
              "page-size": _vm.currentPageSize,
              "page-size-opts": _vm.pageSizeOpts,
              placement: _vm.placement,
              transfer: _vm.transfer,
              "show-elevator": _vm.showElevator,
              current: _vm.currentPage,
              "all-pages": _vm.allPages,
              "is-small": _vm.isSmall
            },
            on: { size: _vm.onSize, page: _vm.onPage }
          })
        ],
        1
      )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("a", [_c("i", { staticClass: "iconfont icon-ios-arrow-back" })])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("a", [
      _c("i", { staticClass: "iconfont icon-ios-arrow-forward" })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("a", [_c("i", { staticClass: "iconfont icon-doubleleft" })])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("a", [_c("i", { staticClass: "iconfont icon-doubleright" })])
  }
]
render._withStripped = true

export { render, staticRenderFns }