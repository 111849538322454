var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      { ref: "point", class: _vm.affixClass, style: _vm.styles },
      [_vm._t("default")],
      2
    ),
    _vm._v(" "),
    _c("div", {
      directives: [
        { name: "show", rawName: "v-show", value: _vm.slot, expression: "slot" }
      ],
      style: _vm.slotStyle
    })
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }