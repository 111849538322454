var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("ul", { class: _vm.classes }, [
    _c(
      "li",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.data.visible,
            expression: "data.visible"
          }
        ]
      },
      [
        _c(
          "div",
          { staticClass: "bin-tree-node" },
          [
            _c(
              "span",
              { class: _vm.arrowClasses, on: { click: _vm.handleExpand } },
              [
                !_vm.isLeaf
                  ? [
                      _vm.showArrow
                        ? _c("b-icon", {
                            attrs: { name: "md-arrow-dropright" }
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.showLoading
                        ? _c("b-icon", {
                            staticClass: "bin-load-loop",
                            attrs: { name: "loading" }
                          })
                        : _vm._e()
                    ]
                  : _vm._e()
              ],
              2
            ),
            _vm._v(" "),
            _vm.showCheckbox
              ? _c("b-checkbox", {
                  attrs: {
                    value: _vm.data.checked,
                    indeterminate: _vm.data.indeterminate,
                    disabled: _vm.data.disabled || _vm.data.disableCheckbox
                  },
                  nativeOn: {
                    click: function($event) {
                      $event.preventDefault()
                      return _vm.handleCheck($event)
                    }
                  }
                })
              : _vm._e(),
            _vm._v(" "),
            _vm.data.render
              ? _c(
                  "span",
                  { class: _vm.renderClasses, on: { click: _vm.handleSelect } },
                  [
                    _c("render", {
                      attrs: {
                        render: _vm.data.render,
                        data: _vm.data,
                        node: _vm.node
                      }
                    })
                  ],
                  1
                )
              : _vm.isParentRender
              ? _c(
                  "span",
                  { class: _vm.renderClasses, on: { click: _vm.handleSelect } },
                  [
                    _c("render", {
                      attrs: {
                        render: _vm.parentRender,
                        data: _vm.data,
                        node: _vm.node
                      }
                    })
                  ],
                  1
                )
              : [
                  _vm.data.display
                    ? _c("span", {
                        class: _vm.titleClasses,
                        domProps: { innerHTML: _vm._s(_vm.data.display) },
                        on: { click: _vm.handleSelect }
                      })
                    : _c(
                        "span",
                        {
                          class: _vm.titleClasses,
                          on: { click: _vm.handleSelect }
                        },
                        [_vm._v(_vm._s(_vm.data.title))]
                      )
                ]
          ],
          2
        ),
        _vm._v(" "),
        _c("collapse-transition", [
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.data.expand,
                  expression: "data.expand"
                }
              ],
              staticClass: "bin-tree-node-children"
            },
            _vm._l(_vm.children, function(item, i) {
              return _c("tree-node", {
                key: i,
                attrs: {
                  data: item,
                  multiple: _vm.multiple,
                  "show-checkbox": _vm.showCheckbox,
                  "children-key": _vm.childrenKey
                }
              })
            }),
            1
          )
        ])
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }