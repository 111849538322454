var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("transition", { attrs: { name: "fade-in-linear" } }, [
    _c("div", { class: _vm.classes }, [
      _c(
        "div",
        { staticClass: "bin-loading-inner" },
        [
          _vm._t("default", [
            !_vm.showIcon
              ? _c("div", [
                  _c(
                    "svg",
                    {
                      staticClass: "circular",
                      attrs: { viewBox: "25 25 50 50" }
                    },
                    [
                      _c("circle", {
                        staticClass: "path",
                        attrs: {
                          cx: "50",
                          cy: "50",
                          r: "20",
                          fill: "none",
                          "stroke-width": "5",
                          "stroke-miterlimit": "10"
                        }
                      })
                    ]
                  )
                ])
              : _c("b-icon", {
                  staticClass: "loading-icon",
                  attrs: { name: _vm.showIcon }
                }),
            _vm._v(" "),
            _vm.showText
              ? _c(
                  "div",
                  { staticClass: "loading-text", style: _vm.textStyle },
                  [_vm._v(_vm._s(_vm.showText))]
                )
              : _vm._e()
          ])
        ],
        2
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }