var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.to
    ? _c(
        "a",
        {
          class: _vm.classes,
          style: _vm.itemStyle,
          attrs: { href: _vm.linkUrl, target: _vm.target },
          on: {
            click: [
              function($event) {
                if (
                  $event.ctrlKey ||
                  $event.shiftKey ||
                  $event.altKey ||
                  $event.metaKey
                ) {
                  return null
                }
                return _vm.handleClickItem($event, false)
              },
              function($event) {
                if (!$event.ctrlKey) {
                  return null
                }
                return _vm.handleClickItem($event, true)
              },
              function($event) {
                if (!$event.metaKey) {
                  return null
                }
                return _vm.handleClickItem($event, true)
              }
            ]
          }
        },
        [_vm._t("default")],
        2
      )
    : _c(
        "li",
        {
          class: _vm.classes,
          style: _vm.itemStyle,
          on: {
            click: function($event) {
              $event.stopPropagation()
              return _vm.handleClickItem($event)
            }
          }
        },
        [_vm._t("default")],
        2
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }