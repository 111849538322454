var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { class: _vm.wrapClasses },
    [
      _vm.always
        ? [
            _c(
              "a",
              { class: _vm.minusClasses, on: { click: _vm.down } },
              [_c("b-icon", { attrs: { name: "ios-remove" } })],
              1
            ),
            _vm._v(" "),
            _c(
              "a",
              { class: _vm.plusClasses, on: { click: _vm.up } },
              [_c("b-icon", { attrs: { name: "ios-add" } })],
              1
            )
          ]
        : _c("div", { class: _vm.handlerClasses }, [
            _c("a", { class: _vm.upClasses, on: { click: _vm.up } }, [
              _c("span", {
                class: _vm.innerUpClasses,
                on: { click: _vm.preventDefault }
              })
            ]),
            _vm._v(" "),
            _c("a", { class: _vm.downClasses, on: { click: _vm.down } }, [
              _c("span", {
                class: _vm.innerDownClasses,
                on: { click: _vm.preventDefault }
              })
            ])
          ]),
      _vm._v(" "),
      _c("div", { class: _vm.inputWrapClasses }, [
        _c("input", {
          class: _vm.inputClasses,
          attrs: {
            id: _vm.elementId,
            disabled: _vm.disabled,
            autocomplete: "off",
            spellcheck: "false",
            autofocus: _vm.autofocus,
            readonly: _vm.readonly || !_vm.editable,
            name: _vm.name,
            placeholder: _vm.placeholder
          },
          domProps: { value: _vm.formatterValue },
          on: {
            focus: _vm.focus,
            blur: _vm.blur,
            keydown: function($event) {
              $event.stopPropagation()
              return _vm.keyDown($event)
            },
            input: _vm.change,
            mouseup: _vm.preventDefault,
            change: _vm.change
          }
        })
      ])
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }