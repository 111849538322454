var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { class: _vm.wrapClasses },
    [
      _vm.type !== "textarea"
        ? [
            _vm.prepend
              ? _c(
                  "span",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.slotReady,
                        expression: "slotReady"
                      }
                    ],
                    class: [_vm.prefixCls + "-group-prepend"]
                  },
                  [_vm._t("prepend")],
                  2
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.clearable && _vm.currentValue && !_vm.disabled
              ? _c("span", { class: _vm.closeClasses }, [
                  _c("i", {
                    staticClass: "iconfont icon-ios-close-circle",
                    on: { click: _vm.handleClear }
                  })
                ])
              : _vm.icon
              ? _c("i", {
                  staticClass: "iconfont",
                  class: [
                    "icon-" + _vm.icon,
                    _vm.prefixCls + "-icon",
                    _vm.prefixCls + "-icon-normal"
                  ],
                  on: { click: _vm.handleIconClick }
                })
              : _vm.search
              ? _c("i", {
                  staticClass: "iconfont icon-ios-search",
                  class: [
                    _vm.prefixCls + "-icon",
                    _vm.prefixCls + "-icon-normal",
                    _vm.prefixCls + "-search-icon"
                  ],
                  on: { click: _vm.handleSearch }
                })
              : _vm.showSuffix
              ? _c(
                  "span",
                  { staticClass: "bin-input-suffix" },
                  [
                    _vm._t("suffix", [
                      _vm.suffix
                        ? _c("i", {
                            staticClass: "iconfont",
                            class: ["icon-" + _vm.suffix]
                          })
                        : _vm._e()
                    ])
                  ],
                  2
                )
              : _vm._e(),
            _vm._v(" "),
            _c("input", {
              ref: "input",
              class: _vm.inputClasses,
              attrs: {
                id: _vm.elementId,
                autocomplete: _vm.autocomplete,
                type: _vm.type,
                placeholder: _vm.placeholder,
                disabled: _vm.disabled,
                maxlength: _vm.maxlength,
                readonly: _vm.readonly,
                name: _vm.name,
                number: _vm.number,
                autofocus: _vm.autofocus
              },
              domProps: { value: _vm.currentValue },
              on: {
                keyup: [
                  function($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    ) {
                      return null
                    }
                    return _vm.handleEnter($event)
                  },
                  _vm.handleKeyup
                ],
                keypress: _vm.handleKeypress,
                keydown: _vm.handleKeydown,
                focus: _vm.handleFocus,
                blur: _vm.handleBlur,
                compositionstart: _vm.handleComposition,
                compositionupdate: _vm.handleComposition,
                compositionend: _vm.handleComposition,
                input: _vm.handleInput,
                change: _vm.handleChange
              }
            }),
            _vm._v(" "),
            _vm.showPrefix
              ? _c(
                  "span",
                  { staticClass: "bin-input-prefix" },
                  [
                    _vm._t("prefix", [
                      _vm.prefix
                        ? _c("i", {
                            staticClass: "iconfont",
                            class: ["icon-" + _vm.prefix]
                          })
                        : _vm._e()
                    ])
                  ],
                  2
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.showWordCount
              ? _c("span", { staticClass: "bin-input-word-count" }, [
                  _vm._v(_vm._s(_vm.wordCount))
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.$slots.append
              ? _c(
                  "span",
                  { staticClass: "bin-input-group-append" },
                  [_vm._t("append")],
                  2
                )
              : _vm._e()
          ]
        : [
            _c("textarea", {
              ref: "textarea",
              class: _vm.textareaClasses,
              style: _vm.textareaStyle,
              attrs: {
                id: _vm.elementId,
                wrap: _vm.wrap,
                autocomplete: _vm.autocomplete,
                spellcheck: _vm.spellcheck,
                placeholder: _vm.placeholder,
                disabled: _vm.disabled,
                rows: _vm.rows,
                maxlength: _vm.maxlength,
                readonly: _vm.readonly,
                name: _vm.name,
                autofocus: _vm.autofocus
              },
              domProps: { value: _vm.currentValue },
              on: {
                keyup: [
                  function($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    ) {
                      return null
                    }
                    return _vm.handleEnter($event)
                  },
                  _vm.handleKeyup
                ],
                keypress: _vm.handleKeypress,
                keydown: _vm.handleKeydown,
                focus: _vm.handleFocus,
                blur: _vm.handleBlur,
                compositionstart: _vm.handleComposition,
                compositionupdate: _vm.handleComposition,
                compositionend: _vm.handleComposition,
                input: _vm.handleInput
              }
            }),
            _vm._v(" "),
            _vm.showWordCount
              ? _c("span", { staticClass: "bin-input-word-count" }, [
                  _vm._v(_vm._s(_vm.wordCount))
                ])
              : _vm._e()
          ]
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }