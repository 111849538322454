var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "nav-wrap", class: { "is-scrollable": _vm.isScrollable } },
    [
      !_vm.hideArrow && _vm.isScrollable
        ? [
            !_vm.hidePrev
              ? _c(
                  "span",
                  { staticClass: "nav-prev", on: { click: _vm.handlePrev } },
                  [_c("b-icon", { attrs: { name: "ios-arrow-back" } })],
                  1
                )
              : _vm._e(),
            _vm._v(" "),
            !_vm.hideNext
              ? _c(
                  "span",
                  { staticClass: "nav-next", on: { click: _vm.handleNext } },
                  [_c("b-icon", { attrs: { name: "ios-arrow-forward" } })],
                  1
                )
              : _vm._e()
          ]
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        {
          ref: "scrollContainer",
          staticClass: "scroll-container",
          on: {
            wheel: function($event) {
              $event.preventDefault()
              return _vm.handleScroll($event)
            }
          }
        },
        [
          _c(
            "div",
            {
              ref: "scrollWrapper",
              staticClass: "scroll-wrapper",
              style: { transform: "translateX(" + _vm.left + "px)" }
            },
            [_vm._t("default")],
            2
          )
        ]
      )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }