var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { class: _vm.classes },
    [
      !_vm.range && _vm.showInput
        ? _c("b-input-number", {
            attrs: {
              min: _vm.min,
              size: _vm.inputSize,
              max: _vm.max,
              step: _vm.step,
              value: _vm.exportValue[0],
              disabled: _vm.itemDisabled,
              "active-change": _vm.activeChange
            },
            on: { change: _vm.handleInputChange }
          })
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        {
          ref: "slider",
          class: [_vm.prefixCls + "-wrap"],
          on: {
            click: function($event) {
              if ($event.target !== $event.currentTarget) {
                return null
              }
              return _vm.sliderClick($event)
            }
          }
        },
        [
          _c("input", {
            attrs: { type: "hidden", name: _vm.name },
            domProps: { value: _vm.exportValue }
          }),
          _vm._v(" "),
          _c("div", {
            class: [_vm.prefixCls + "-bar"],
            style: _vm.barStyle,
            on: {
              click: function($event) {
                if ($event.target !== $event.currentTarget) {
                  return null
                }
                return _vm.sliderClick($event)
              }
            }
          }),
          _vm._v(" "),
          _vm.showStops
            ? _vm._l(_vm.stops, function(item, index) {
                return _c("div", {
                  key: index,
                  class: [_vm.prefixCls + "-stop"],
                  style: { left: item + "%" },
                  on: {
                    click: function($event) {
                      if ($event.target !== $event.currentTarget) {
                        return null
                      }
                      return _vm.sliderClick($event)
                    }
                  }
                })
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.markList.length > 0
            ? [
                _vm._l(_vm.markList, function(item, key) {
                  return _c("div", {
                    key: key,
                    class: [_vm.prefixCls + "-stop"],
                    style: { left: item.position + "%" },
                    on: {
                      click: function($event) {
                        if ($event.target !== $event.currentTarget) {
                          return null
                        }
                        return _vm.sliderClick($event)
                      }
                    }
                  })
                }),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "bin-slider-marks" },
                  _vm._l(_vm.markList, function(item, key) {
                    return _c("SliderMarker", {
                      key: key,
                      style: { left: item.position + "%" },
                      attrs: { mark: item.mark },
                      nativeOn: {
                        click: function($event) {
                          return _vm.sliderClick($event)
                        }
                      }
                    })
                  }),
                  1
                )
              ]
            : _vm._e(),
          _vm._v(" "),
          _c(
            "div",
            {
              class: [_vm.prefixCls + "-button-wrap"],
              style: { left: _vm.minPosition + "%" },
              on: {
                touchstart: function($event) {
                  return _vm.onPointerDown($event, "min")
                },
                mousedown: function($event) {
                  return _vm.onPointerDown($event, "min")
                }
              }
            },
            [
              _c(
                "b-tooltip",
                {
                  ref: "minTooltip",
                  attrs: {
                    controlled: _vm.pointerDown === "min",
                    placement: "top",
                    content: _vm.tipFormat(_vm.exportValue[0]),
                    disabled: _vm.tipDisabled,
                    always: _vm.showTip === "always"
                  }
                },
                [
                  _c("div", {
                    class: _vm.minButtonClasses,
                    attrs: { tabindex: "0" },
                    on: {
                      focus: function($event) {
                        return _vm.handleFocus("min")
                      },
                      blur: function($event) {
                        return _vm.handleBlur("min")
                      },
                      keydown: [
                        function($event) {
                          if (
                            !$event.type.indexOf("key") &&
                            _vm._k($event.keyCode, "left", 37, $event.key, [
                              "Left",
                              "ArrowLeft"
                            ])
                          ) {
                            return null
                          }
                          if ("button" in $event && $event.button !== 0) {
                            return null
                          }
                          return _vm.onKeyLeft($event, "min")
                        },
                        function($event) {
                          if (
                            !$event.type.indexOf("key") &&
                            _vm._k($event.keyCode, "down", 40, $event.key, [
                              "Down",
                              "ArrowDown"
                            ])
                          ) {
                            return null
                          }
                          return _vm.onKeyLeft($event, "min")
                        },
                        function($event) {
                          if (
                            !$event.type.indexOf("key") &&
                            _vm._k($event.keyCode, "right", 39, $event.key, [
                              "Right",
                              "ArrowRight"
                            ])
                          ) {
                            return null
                          }
                          if ("button" in $event && $event.button !== 2) {
                            return null
                          }
                          return _vm.onKeyRight($event, "min")
                        },
                        function($event) {
                          if (
                            !$event.type.indexOf("key") &&
                            _vm._k($event.keyCode, "up", 38, $event.key, [
                              "Up",
                              "ArrowUp"
                            ])
                          ) {
                            return null
                          }
                          return _vm.onKeyRight($event, "min")
                        }
                      ]
                    }
                  })
                ]
              )
            ],
            1
          ),
          _vm._v(" "),
          _vm.range
            ? _c(
                "div",
                {
                  class: [_vm.prefixCls + "-button-wrap"],
                  style: { left: _vm.maxPosition + "%" },
                  on: {
                    touchstart: function($event) {
                      return _vm.onPointerDown($event, "max")
                    },
                    mousedown: function($event) {
                      return _vm.onPointerDown($event, "max")
                    }
                  }
                },
                [
                  _c(
                    "b-tooltip",
                    {
                      ref: "maxTooltip",
                      attrs: {
                        controlled: _vm.pointerDown === "max",
                        placement: "top",
                        content: _vm.tipFormat(_vm.exportValue[1]),
                        disabled: _vm.tipDisabled,
                        always: _vm.showTip === "always"
                      }
                    },
                    [
                      _c("div", {
                        class: _vm.maxButtonClasses,
                        attrs: { tabindex: "0" },
                        on: {
                          focus: function($event) {
                            return _vm.handleFocus("max")
                          },
                          blur: function($event) {
                            return _vm.handleBlur("max")
                          },
                          keydown: [
                            function($event) {
                              if (
                                !$event.type.indexOf("key") &&
                                _vm._k($event.keyCode, "left", 37, $event.key, [
                                  "Left",
                                  "ArrowLeft"
                                ])
                              ) {
                                return null
                              }
                              if ("button" in $event && $event.button !== 0) {
                                return null
                              }
                              return _vm.onKeyLeft($event, "max")
                            },
                            function($event) {
                              if (
                                !$event.type.indexOf("key") &&
                                _vm._k($event.keyCode, "down", 40, $event.key, [
                                  "Down",
                                  "ArrowDown"
                                ])
                              ) {
                                return null
                              }
                              return _vm.onKeyLeft($event, "max")
                            },
                            function($event) {
                              if (
                                !$event.type.indexOf("key") &&
                                _vm._k(
                                  $event.keyCode,
                                  "right",
                                  39,
                                  $event.key,
                                  ["Right", "ArrowRight"]
                                )
                              ) {
                                return null
                              }
                              if ("button" in $event && $event.button !== 2) {
                                return null
                              }
                              return _vm.onKeyRight($event, "max")
                            },
                            function($event) {
                              if (
                                !$event.type.indexOf("key") &&
                                _vm._k($event.keyCode, "up", 38, $event.key, [
                                  "Up",
                                  "ArrowUp"
                                ])
                              ) {
                                return null
                              }
                              return _vm.onKeyRight($event, "max")
                            }
                          ]
                        }
                      })
                    ]
                  )
                ],
                1
              )
            : _vm._e()
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }