var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { class: [_vm.prefixCls] },
    [
      _c(
        "div",
        {
          class: _vm.classes,
          on: {
            click: _vm.handleClick,
            drop: function($event) {
              $event.preventDefault()
              return _vm.onDrop($event)
            },
            paste: _vm.handlePaste,
            dragover: function($event) {
              $event.preventDefault()
              _vm.dragOver = true
            },
            dragleave: function($event) {
              $event.preventDefault()
              _vm.dragOver = false
            }
          }
        },
        [
          _c("input", {
            ref: "input",
            class: [_vm.prefixCls + "-input"],
            attrs: { type: "file", multiple: _vm.multiple, accept: _vm.accept },
            on: { change: _vm.handleChange }
          }),
          _vm._v(" "),
          _vm._t("default", [
            _c("b-button", { attrs: { icon: "ios-cloud-upload" } }, [
              _vm._v(" 点击上传")
            ])
          ])
        ],
        2
      ),
      _vm._v(" "),
      _vm._t("tip"),
      _vm._v(" "),
      _vm.showUploadList
        ? _c("upload-list", {
            attrs: { files: _vm.fileList },
            on: {
              "file-remove": _vm.handleRemove,
              "file-preview": _vm.handlePreview
            }
          })
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }