var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("li", { class: _vm.itemClasses }, [
    _c("div", { class: _vm.tailClasses }),
    _vm._v(" "),
    _c(
      "div",
      { ref: "dot", class: _vm.headClasses, style: _vm.customStyle },
      [_vm._t("dot")],
      2
    ),
    _vm._v(" "),
    _c("div", { class: _vm.contentClasses }, [_vm._t("default")], 2)
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }