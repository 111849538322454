import { render, staticRenderFns } from "./date-range.vue?vue&type=template&id=4b0c115c&"
import script from "./date-range.vue?vue&type=script&lang=js&"
export * from "./date-range.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/Users/wangbin/workspace/project/bin-ui/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('4b0c115c')) {
      api.createRecord('4b0c115c', component.options)
    } else {
      api.reload('4b0c115c', component.options)
    }
    module.hot.accept("./date-range.vue?vue&type=template&id=4b0c115c&", function () {
      api.rerender('4b0c115c', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/date-picker/panel/Date/date-range.vue"
export default component.exports