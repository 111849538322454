var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "click-outside",
          rawName: "v-click-outside",
          value: _vm.handleClose,
          expression: "handleClose"
        }
      ],
      class: _vm.classes
    },
    [
      _c(
        "div",
        {
          ref: "reference",
          class: _vm.wrapClasses,
          on: { click: _vm.toggleVisible }
        },
        [
          _c("input", {
            attrs: { name: _vm.name, type: "hidden" },
            domProps: { value: _vm.currentValue }
          }),
          _vm._v(" "),
          _c(
            "div",
            {
              ref: "input",
              class: _vm.inputClasses,
              attrs: { tabindex: _vm.itemDisabled ? undefined : 0 },
              on: {
                keydown: [
                  function($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "tab", 9, $event.key, "Tab")
                    ) {
                      return null
                    }
                    return _vm.onTab($event)
                  },
                  function($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "esc", 27, $event.key, [
                        "Esc",
                        "Escape"
                      ])
                    ) {
                      return null
                    }
                    return _vm.onEscape($event)
                  },
                  function($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "up", 38, $event.key, [
                        "Up",
                        "ArrowUp"
                      ])
                    ) {
                      return null
                    }
                    return _vm.onArrow($event)
                  },
                  function($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "down", 40, $event.key, [
                        "Down",
                        "ArrowDown"
                      ])
                    ) {
                      return null
                    }
                    return _vm.onArrow($event)
                  }
                ]
              }
            },
            [
              _c("div", { class: [_vm.prefixCls + "-color"] }, [
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.value === "" && !_vm.visible,
                        expression: "value === '' && !visible"
                      }
                    ],
                    class: [_vm.prefixCls + "-color-empty"]
                  },
                  [_c("b-icon", { attrs: { name: "ios-close" } })],
                  1
                ),
                _vm._v(" "),
                _c("div", {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.value || _vm.visible,
                      expression: "value || visible"
                    }
                  ],
                  style: _vm.displayedColorStyle
                })
              ])
            ]
          )
        ]
      ),
      _vm._v(" "),
      _c(
        "transition",
        { attrs: { name: "zoom-in-top" } },
        [
          _c(
            "drop",
            {
              directives: [
                { name: "transfer-dom", rawName: "v-transfer-dom" },
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.visible,
                  expression: "visible"
                }
              ],
              ref: "drop",
              class: _vm.dropClasses,
              attrs: {
                placement: _vm.placement,
                "data-transfer": _vm.appendToBody,
                transfer: _vm.appendToBody
              }
            },
            [
              _c("transition", { attrs: { name: "fade-in" } }, [
                _vm.visible
                  ? _c("div", { class: [_vm.prefixCls + "-picker"] }, [
                      _c(
                        "div",
                        { class: [_vm.prefixCls + "-picker-wrapper"] },
                        [
                          _c(
                            "div",
                            { class: [_vm.prefixCls + "-picker-panel"] },
                            [
                              _c("Saturation", {
                                ref: "saturation",
                                attrs: { focused: _vm.visible },
                                on: { change: _vm.childChange },
                                nativeOn: {
                                  keydown: function($event) {
                                    if (
                                      !$event.type.indexOf("key") &&
                                      _vm._k(
                                        $event.keyCode,
                                        "tab",
                                        9,
                                        $event.key,
                                        "Tab"
                                      )
                                    ) {
                                      return null
                                    }
                                    return _vm.handleFirstTab($event)
                                  }
                                },
                                model: {
                                  value: _vm.saturationColors,
                                  callback: function($$v) {
                                    _vm.saturationColors = $$v
                                  },
                                  expression: "saturationColors"
                                }
                              })
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _vm.hue
                            ? _c(
                                "div",
                                {
                                  class: [_vm.prefixCls + "-picker-hue-slider"]
                                },
                                [
                                  _c("Hue", {
                                    on: { change: _vm.childChange },
                                    model: {
                                      value: _vm.saturationColors,
                                      callback: function($$v) {
                                        _vm.saturationColors = $$v
                                      },
                                      expression: "saturationColors"
                                    }
                                  })
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.alpha
                            ? _c(
                                "div",
                                {
                                  class: [
                                    _vm.prefixCls + "-picker-alpha-slider"
                                  ]
                                },
                                [
                                  _c("Alpha", {
                                    on: { change: _vm.childChange },
                                    model: {
                                      value: _vm.saturationColors,
                                      callback: function($$v) {
                                        _vm.saturationColors = $$v
                                      },
                                      expression: "saturationColors"
                                    }
                                  })
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.colors.length
                            ? _c("recommend-colors", {
                                class: [_vm.prefixCls + "-picker-colors"],
                                attrs: { list: _vm.colors },
                                on: { "picker-color": _vm.handleSelectColor }
                              })
                            : _vm._e(),
                          _vm._v(" "),
                          !_vm.colors.length && _vm.recommend
                            ? _c("recommend-colors", {
                                class: [_vm.prefixCls + "-picker-colors"],
                                attrs: { list: _vm.recommendedColor },
                                on: { "picker-color": _vm.handleSelectColor }
                              })
                            : _vm._e()
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { class: [_vm.prefixCls + "-confirm"] },
                        [
                          _c(
                            "span",
                            { class: _vm.confirmColorClasses },
                            [
                              _vm.editable
                                ? [
                                    _c("b-input", {
                                      attrs: {
                                        value: _vm.formatColor,
                                        size: "mini"
                                      },
                                      on: {
                                        enter: _vm.handleEditColor,
                                        blur: _vm.handleEditColor
                                      }
                                    })
                                  ]
                                : [_vm._v(_vm._s(_vm.formatColor))]
                            ],
                            2
                          ),
                          _vm._v(" "),
                          _c(
                            "b-button",
                            {
                              ref: "clear",
                              attrs: { tabindex: 0, size: "mini" },
                              on: {
                                keydown: function($event) {
                                  if (
                                    !$event.type.indexOf("key") &&
                                    _vm._k(
                                      $event.keyCode,
                                      "enter",
                                      13,
                                      $event.key,
                                      "Enter"
                                    )
                                  ) {
                                    return null
                                  }
                                  return _vm.handleClear($event)
                                }
                              },
                              nativeOn: {
                                click: function($event) {
                                  return _vm.handleClear($event)
                                },
                                keydown: function($event) {
                                  if (
                                    !$event.type.indexOf("key") &&
                                    _vm._k(
                                      $event.keyCode,
                                      "esc",
                                      27,
                                      $event.key,
                                      ["Esc", "Escape"]
                                    )
                                  ) {
                                    return null
                                  }
                                  return _vm.closer($event)
                                }
                              }
                            },
                            [_vm._v("清空\n            ")]
                          ),
                          _vm._v(" "),
                          _c(
                            "b-button",
                            {
                              ref: "ok",
                              attrs: {
                                tabindex: 0,
                                size: "mini",
                                type: "primary"
                              },
                              on: {
                                keydown: function($event) {
                                  if (
                                    !$event.type.indexOf("key") &&
                                    _vm._k(
                                      $event.keyCode,
                                      "enter",
                                      13,
                                      $event.key,
                                      "Enter"
                                    )
                                  ) {
                                    return null
                                  }
                                  return _vm.handleSuccess($event)
                                }
                              },
                              nativeOn: {
                                click: function($event) {
                                  return _vm.handleSuccess($event)
                                },
                                keydown: [
                                  function($event) {
                                    if (
                                      !$event.type.indexOf("key") &&
                                      _vm._k(
                                        $event.keyCode,
                                        "tab",
                                        9,
                                        $event.key,
                                        "Tab"
                                      )
                                    ) {
                                      return null
                                    }
                                    return _vm.handleLastTab($event)
                                  },
                                  function($event) {
                                    if (
                                      !$event.type.indexOf("key") &&
                                      _vm._k(
                                        $event.keyCode,
                                        "esc",
                                        27,
                                        $event.key,
                                        ["Esc", "Escape"]
                                      )
                                    ) {
                                      return null
                                    }
                                    return _vm.closer($event)
                                  }
                                ]
                              }
                            },
                            [_vm._v("确定\n            ")]
                          )
                        ],
                        1
                      )
                    ])
                  : _vm._e()
              ])
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }