var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "transition",
    {
      attrs: { name: _vm.transitionName, appear: "" },
      on: { enter: _vm.handleEnter, leave: _vm.handleLeave }
    },
    [
      _vm.type === "notice"
        ? [
            _c("div", { class: _vm.noticeClasses }, [
              _c("div", {
                ref: "content",
                class: [
                  "bin-notice-content",
                  this.render !== undefined
                    ? "bin-notice-content-with-render"
                    : ""
                ],
                domProps: { innerHTML: _vm._s(_vm.content) }
              }),
              _vm._v(" "),
              _c(
                "div",
                { class: _vm.contentWithIcon },
                [_c("render-cell", { attrs: { render: _vm.renderFunc } })],
                1
              ),
              _vm._v(" "),
              _vm.closable
                ? _c(
                    "a",
                    {
                      staticClass: "bin-notice-close",
                      on: { click: _vm.close }
                    },
                    [_c("i", { staticClass: "iconfont icon-ios-close" })]
                  )
                : _vm._e()
            ])
          ]
        : [
            _c("div", { class: _vm.classes }, [
              _c(
                "div",
                {
                  ref: "content",
                  class: [
                    _vm.baseClass + "-content",
                    _vm.baseClass + "-" + _vm.type
                  ]
                },
                [
                  _vm.content
                    ? _c(
                        "div",
                        { class: [_vm.baseClass + "-content-text"] },
                        [
                          _c("b-icon", { attrs: { name: _vm.iconTypes } }),
                          _vm._v(" "),
                          _c("span", [_vm._v(_vm._s(_vm.content))])
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "div",
                    { class: [_vm.baseClass + "-content-text"] },
                    [_c("render-cell", { attrs: { render: _vm.renderFunc } })],
                    1
                  ),
                  _vm._v(" "),
                  _vm.closable
                    ? _c(
                        "a",
                        {
                          class: [_vm.baseClass + "-close"],
                          on: { click: _vm.close }
                        },
                        [_c("i", { staticClass: "iconfont icon-ios-close" })]
                      )
                    : _vm._e()
                ]
              )
            ])
          ]
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }