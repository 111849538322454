var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { class: _vm.classes },
    _vm._l(_vm.cells, function(cell, index) {
      return _c(
        "span",
        {
          key: index,
          class: _vm.getCellCls(cell),
          on: {
            click: function($event) {
              $event.stopPropagation()
              return _vm.handleClick(cell)
            },
            mouseenter: function($event) {
              return _vm.handleMouseMove(cell)
            }
          }
        },
        [_c("em", [_vm._v(_vm._s(cell.date.getFullYear()))])]
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }