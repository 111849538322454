var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { class: _vm.prefixCls },
    [
      !_vm.isEmpty
        ? _vm._l(_vm.stateTree, function(item, i) {
            return _c("tree-node", {
              key: i,
              attrs: {
                data: item,
                multiple: _vm.multiple,
                "show-checkbox": _vm.showCheckbox,
                "children-key": _vm.childrenKey
              }
            })
          })
        : _c(
            "div",
            [
              _c("b-empty", { staticStyle: { margin: "16px 0" } }, [
                _vm._v(_vm._s(_vm.emptyText))
              ])
            ],
            1
          )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }