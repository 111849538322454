var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("label", { class: _vm.wrapClasses }, [
    _c("span", { class: _vm.checkboxClasses }, [
      _c("span", { class: _vm.innerClasses }),
      _vm._v(" "),
      _vm.group
        ? _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.model,
                expression: "model"
              }
            ],
            class: _vm.inputClasses,
            attrs: { type: "checkbox", disabled: _vm.disabled, name: _vm.name },
            domProps: {
              value: _vm.label,
              checked: Array.isArray(_vm.model)
                ? _vm._i(_vm.model, _vm.label) > -1
                : _vm.model
            },
            on: {
              change: [
                function($event) {
                  var $$a = _vm.model,
                    $$el = $event.target,
                    $$c = $$el.checked ? true : false
                  if (Array.isArray($$a)) {
                    var $$v = _vm.label,
                      $$i = _vm._i($$a, $$v)
                    if ($$el.checked) {
                      $$i < 0 && (_vm.model = $$a.concat([$$v]))
                    } else {
                      $$i > -1 &&
                        (_vm.model = $$a
                          .slice(0, $$i)
                          .concat($$a.slice($$i + 1)))
                    }
                  } else {
                    _vm.model = $$c
                  }
                },
                _vm.change
              ],
              focus: _vm.onFocus,
              blur: _vm.onBlur
            }
          })
        : _c("input", {
            class: _vm.inputClasses,
            attrs: { type: "checkbox", disabled: _vm.disabled, name: _vm.name },
            domProps: { checked: _vm.currentValue },
            on: { change: _vm.change, focus: _vm.onFocus, blur: _vm.onBlur }
          })
    ]),
    _vm._v(" "),
    _vm.showSlot
      ? _c("span", [_vm._t("default", [_vm._v(_vm._s(_vm.label))])], 2)
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }