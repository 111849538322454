var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "bin-cascader-panel" },
    [
      _vm.data && _vm.data.length
        ? _c(
            "ul",
            { class: [_vm.prefixCls + "-menu"] },
            _vm._l(_vm.data, function(item, index) {
              return _c("cas-item", {
                key: index,
                attrs: {
                  "prefix-cls": _vm.prefixCls,
                  data: item,
                  "tmp-item": _vm.tmpItem
                },
                nativeOn: {
                  click: function($event) {
                    $event.stopPropagation()
                    return _vm.handleClickItem(item)
                  },
                  mouseenter: function($event) {
                    $event.stopPropagation()
                    return _vm.handleHoverItem(item)
                  }
                }
              })
            }),
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.sublist && _vm.sublist.length
        ? _c("cas-panel", {
            attrs: {
              "prefix-cls": _vm.prefixCls,
              data: _vm.sublist,
              disabled: _vm.disabled,
              trigger: _vm.trigger,
              "change-on-select": _vm.changeOnSelect
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }