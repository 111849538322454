var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { class: _vm.classes }, [
    _c(
      "button",
      {
        staticClass: "left",
        class: _vm.arrowClasses,
        attrs: { type: "button" },
        on: {
          click: function($event) {
            return _vm.arrowEvent(-1)
          }
        }
      },
      [_c("b-icon", { attrs: { name: "ios-arrow-back" } })],
      1
    ),
    _vm._v(" "),
    _c("div", { class: [_vm.prefixCls + "-list"] }, [
      _c(
        "div",
        {
          ref: "originTrack",
          class: [_vm.prefixCls + "-track", _vm.showCopyTrack ? "" : "higher"],
          style: _vm.trackStyles
        },
        [_vm._t("default")],
        2
      ),
      _vm._v(" "),
      _vm.loop
        ? _c("div", {
            ref: "copyTrack",
            class: [
              _vm.prefixCls + "-track",
              _vm.showCopyTrack ? "higher" : ""
            ],
            style: _vm.copyTrackStyles
          })
        : _vm._e()
    ]),
    _vm._v(" "),
    _c(
      "button",
      {
        staticClass: "right",
        class: _vm.arrowClasses,
        attrs: { type: "button" },
        on: {
          click: function($event) {
            return _vm.arrowEvent(1)
          }
        }
      },
      [_c("b-icon", { attrs: { name: "ios-arrow-forward" } })],
      1
    ),
    _vm._v(" "),
    _c(
      "ul",
      { class: _vm.dotsClasses },
      [
        _vm._l(_vm.slides.length, function(n) {
          return [
            _c(
              "li",
              {
                key: n,
                class: [
                  n - 1 === _vm.currentIndex ? _vm.prefixCls + "-active" : ""
                ],
                on: {
                  click: function($event) {
                    return _vm.dotsEvent("click", n - 1)
                  },
                  mouseover: function($event) {
                    return _vm.dotsEvent("hover", n - 1)
                  }
                }
              },
              [
                _c("button", {
                  class: [_vm.radiusDot ? "radius" : ""],
                  style: {
                    background:
                      n - 1 === _vm.currentIndex
                        ? _vm.activeColor
                        : _vm.defaultColor
                  },
                  attrs: { type: "button" }
                })
              ]
            )
          ]
        })
      ],
      2
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }