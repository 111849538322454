var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "click-outside",
          rawName: "v-click-outside",
          value: _vm.handleClose,
          expression: "handleClose"
        }
      ],
      class: _vm.classes,
      on: { mouseenter: _vm.handleMouseenter, mouseleave: _vm.handleMouseleave }
    },
    [
      _c(
        "div",
        {
          ref: "reference",
          class: [_vm.prefixCls + "-rel"],
          on: {
            click: _vm.handleClick,
            mousedown: function($event) {
              return _vm.handleFocus(false)
            },
            mouseup: function($event) {
              return _vm.handleBlur(false)
            }
          }
        },
        [_vm._t("default")],
        2
      ),
      _vm._v(" "),
      _c("transition", { attrs: { name: "fade-in-linear" } }, [
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.visible,
                expression: "visible"
              },
              { name: "transfer-dom", rawName: "v-transfer-dom" }
            ],
            ref: "popper",
            class: _vm.popperClasses,
            style: _vm.styles,
            attrs: { "data-transfer": _vm.appendToBody },
            on: {
              click: _vm.handleTransferClick,
              mouseenter: _vm.handleMouseenter,
              mouseleave: _vm.handleMouseleave
            }
          },
          [
            _c("div", { class: [_vm.prefixCls + "-content"] }, [
              _c("div", { class: [_vm.prefixCls + "-arrow"] }),
              _vm._v(" "),
              _vm.confirm
                ? _c("div", { class: [_vm.prefixCls + "-inner"] }, [
                    _c("div", { class: [_vm.prefixCls + "-body"] }, [
                      _c(
                        "div",
                        { class: [_vm.prefixCls + "-body-message"] },
                        [
                          _c("i", {
                            class: "iconfont icon-" + _vm.iconName,
                            style: _vm.iconStyles
                          }),
                          _vm._v(" "),
                          _vm._t("title", [
                            _c("span", { staticClass: "message-title" }, [
                              _vm._v(_vm._s(_vm.title))
                            ])
                          ])
                        ],
                        2
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { class: [_vm.prefixCls + "-body-buttons"] },
                        [
                          _c(
                            "b-button",
                            {
                              attrs: { size: "mini" },
                              on: { click: _vm.cancel }
                            },
                            [_vm._v(_vm._s(_vm.cancelText))]
                          ),
                          _vm._v(" "),
                          _c(
                            "b-button",
                            {
                              attrs: { type: "primary", size: "mini" },
                              on: { click: _vm.ok }
                            },
                            [_vm._v(_vm._s(_vm.okText))]
                          )
                        ],
                        1
                      )
                    ])
                  ])
                : _vm._e(),
              _vm._v(" "),
              !_vm.confirm
                ? _c("div", { class: [_vm.prefixCls + "-inner"] }, [
                    _vm.showTitle
                      ? _c(
                          "div",
                          {
                            ref: "title",
                            class: [_vm.prefixCls + "-title"],
                            style: _vm.contentPaddingStyle
                          },
                          [
                            _vm._t("title", [
                              _c(
                                "div",
                                { class: [_vm.prefixCls + "-title-inner"] },
                                [_vm._v(_vm._s(_vm.title))]
                              )
                            ])
                          ],
                          2
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        class: [_vm.prefixCls + "-body"],
                        style: _vm.contentPaddingStyle
                      },
                      [
                        _c(
                          "div",
                          { class: _vm.contentClasses },
                          [
                            _vm._t("content", [
                              _c(
                                "div",
                                {
                                  class: [_vm.prefixCls + "-body-content-inner"]
                                },
                                [_vm._v(_vm._s(_vm.content))]
                              )
                            ])
                          ],
                          2
                        )
                      ]
                    )
                  ])
                : _vm._e()
            ])
          ]
        )
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }